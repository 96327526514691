interface OversightNoReportsProps {
    title?: JSX.Element;
    dis?: JSX.Element;
}

const OversightNoReport: React.FC<OversightNoReportsProps> = ({ title, dis }) => {
    return (
        <div className='no_report  '>
            {title}
            {dis}
        </div>
    );
};

export default OversightNoReport;
