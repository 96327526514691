import React from 'react';

import Markdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';

interface MarkdownRendererProps {
    children: string;
}

const preprocessMarkdown = (markdown: string) => {
    return markdown
        .replace(/\.(#+)/g, (match, hashes) => `.<br />${hashes}`)
        .replace(/<br\s*\/?>\n*/gi, '\n')
        .replace(/```[\s\S]*?```/g, (match) => `\n${match}\n`)
        .replace(/\n{2,}/g, '\n\n');
};

const parseMarkdownTable = (code: string) => {
    const lines = code.split('\n');
    let html = '';
    let inTable = false;

    lines.forEach((line) => {
        const trimmedLine = line.trim();

        // Ignore lines that are purely separators
        if (trimmedLine.startsWith('|') && trimmedLine.endsWith('|')) {
            // Split the line into columns and check if they are all dashes
            const columns = trimmedLine.split('|').map((col) => col.trim());
            const allDashes = columns.every((col) => /^[-:\s]*$/.test(col));

            if (allDashes) {
                return; // Skip this separator line
            }
        }

        // Process valid table lines
        if (trimmedLine.startsWith('|') && trimmedLine.endsWith('|')) {
            const columns = trimmedLine
                .split('|')
                .filter(Boolean) // Remove empty strings
                .map((col) => col.trim()); // Trim whitespace

            if (!inTable) {
                // Start a new table
                html += '<table><thead><tr>';
                inTable = true;

                // Add header row
                columns.forEach((col) => {
                    html += `<th>${col}</th>`;
                });
                html += '</tr></thead><tbody>';
            } else {
                // Add data rows
                html += '<tr>';
                columns.forEach((col) => {
                    html += `<td>${col}</td>`;
                });
                html += '</tr>';
            }
        } else if (inTable && !trimmedLine.startsWith('|')) {
            // Close the table if we encounter a line that is not a part of the table
            html += '</tbody></table>';
            inTable = false;
        }
    });

    if (inTable) {
        // Close the table if it was still open
        html += '</tbody></table>';
    }

    return html;
};

const MarkdownRenderer: React.FC<MarkdownRendererProps> = ({ children }) => {
    const processedMarkdown = preprocessMarkdown(children);

    return (
        <Markdown
            components={{
                code({ inline, className, children }) {
                    const language = className ? className.replace('language-', '') : '';

                    if (language === 'markdown' && !inline) {
                        // Process only the specific markdown table content
                        const tableContent = children?.[0] || '';

                        return (
                            <>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: parseMarkdownTable(String(tableContent)),
                                    }}
                                />
                                <br />
                            </>
                        );
                    }

                    if (!inline) {
                        return (
                            <SyntaxHighlighter language={language}>
                                {String(children).replace(/\n$/, '')}
                            </SyntaxHighlighter>
                        );
                    }

                    return <code>{children}</code>;
                },
            }}
        >
            {processedMarkdown}
        </Markdown>
    );
};

export default MarkdownRenderer;
