import { useState, useEffect } from 'react';

import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { appInsights } from '../../../../../../AppInsights';
import { ReactComponent as SendIcon } from '../../../../../../assets/icons/Send_Icon.svg';
import { ReactComponent as SwitchOff } from '../../../../../../assets/icons/Switch_OFF.svg';
import { ReactComponent as SwitchOn } from '../../../../../../assets/icons/Switch_On.svg';
import { ReactComponent as SwitchOnHover } from '../../../../../../assets/icons/Switch_OnHover.svg';
import useMediaQuery from '../../../../../../hooks/useMediaQuery';
import { useOrganisationForm } from '../../../../../../hooks/useOrganisation';
import {
    setChildScreen,
    setClickProtocol,
    setCreateReport,
    setEvaIds,
    setGeneratedReportType,
    setNewMetrics,
    setNewProtocols,
    setNewSelectedProtocols,
    setOversightOption,
    setParentSessionId,
    setPrvMetrics,
    setPrvPrompt,
    setPrvProtocols,
    setReportLoading,
    setScreen,
    setSelectMetrics,
    setSessionsId,
    setSessionsReport,
} from '../../../../../../Redux/SessionsProtocols';
import { RootState } from '../../../../../../Redux/store';
import { EvaService } from '../../../../../../services/eva/eva.service';
import { ROLES, SIZES } from '../../../../../../utils/constants';
import { AnimatedCircle } from '../../../../../basic/AnimatedCircle';
import { CreateReportDto } from '../../../evaAnalytics/components/analyticsChart/types';
import { EvaType } from '../../types';
import { EvaOrbit } from '.././orbit/EvaOrbit';
import OversightSummaries from './OversightSummaries';

interface CreateEvaModelProps {
    isCreateEvaModalOpen: boolean;
    setPublishedEvaExists: (value: boolean) => void;
    setCreateEvaModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setDraftEvaExists: (value: boolean) => void;
    labelShow: boolean;
    protocolIndex: number | null;
}

export const PublishedPlanet: React.FC<CreateEvaModelProps> = ({
    isCreateEvaModalOpen,
    setCreateEvaModalOpen,
    setPublishedEvaExists,
    setDraftEvaExists,
    labelShow,
    protocolIndex,
}) => {
    const isMobile = useMediaQuery(SIZES.MOBILE_WIDTH);

    const { id: organisationId } = useParams<{ id: string }>();
    const { getEvaByOrganisationId, makeEvaEnableDisable, getUserPermission } = useOrganisationForm();
    const [transferSwitch, setTransferSwitch] = useState<boolean>(true);
    const [evaId, setEvaId] = useState<string | undefined>();
    const [inputVal, setInputVal] = useState<string>('');
    const [sendVal, setSendVal] = useState<string>('');

    const prvReport = useSelector((state: RootState) => state.SessionsProtocols.SessionsReport);

    const prvProtocols = useSelector((state: RootState) => state.SessionsProtocols.PrvProtocols);

    const checkClickProtocol = useSelector((state: RootState) => state.SessionsProtocols.clickProtocol);
    const generatedReportType = useSelector((state: RootState) => state.SessionsProtocols.generatedReportType);
    const NewSelectedProtocols = useSelector((state: RootState) => state.SessionsProtocols.newSelectProtocols);
    const SessionsId = useSelector((state: RootState) => state.SessionsProtocols.SessionsId);
    const parentSessionId = useSelector((state: RootState) => state.SessionsProtocols.ParentSessionId);
    const childScreen = useSelector((state: RootState) => state.SessionsProtocols.childScreen);
    const screen = useSelector((state: RootState) => state.SessionsProtocols.screen);
    const prevPrompt = useSelector((state: RootState) => state.SessionsProtocols.prvPrompt);
    const selectMetrics = useSelector((state: RootState) => state.SessionsProtocols.selectMetrics);
    const prvMetrics = useSelector((state: RootState) => state.SessionsProtocols.prvMetrics);
    const option = useSelector((state: RootState) => state.SessionsProtocols.OversightOption);

    const [checkSessionsId, setCheckSessionsId] = useState(false);

    const [reportError, setReportError] = useState<string | undefined>(undefined);

    const [checkInput, setCheckInput] = useState(false);

    const dispatch = useDispatch();

    const evaService = new EvaService();

    const { data: userPermit } = useQuery(['getUserPermission', organisationId], () => {
        try {
            return getUserPermission(organisationId);
        } catch (e) {
            console.error(e);
            if (e instanceof Error) {
                appInsights.trackException({ error: e });
            }
        }
    });

    const { isLoading, isFetching, refetch } = useQuery(
        ['getEvaByOrganisationId', organisationId, EvaType.publish],
        () => getEvaByOrganisationId(organisationId, EvaType.publish),
        {
            onSuccess: (result) => {
                if (result.id !== null) {
                    if (result) {
                        setEvaId(result.id);
                        dispatch(setEvaIds(result.id));
                        setTransferSwitch(result.isEvaEnable);
                    }
                }
            },
            onError: (error: Error) => {
                appInsights.trackException({ error: error });
            },
        },
    );

    const { data: evaSummary, isLoading: evaSummaryLoading } = useQuery(['getLatestEvaUpdate', evaId], () => {
        try {
            if (evaId) return evaService.getLatestEvaUpdate(evaId);
        } catch (e) {
            console.error(e);
            if (e instanceof Error) {
                appInsights.trackException({ error: e });
            }
        }
    });

    const reportName = inputVal.length > 0 ? inputVal : prvReport !== null ? prvReport.report_name : inputVal;
    const prompt = option.length > 0 ? '' : inputVal;

    const sessionId =
        childScreen === 'History' && generatedReportType == 'guided'
            ? sendVal === 'hit'
                ? null
                : SessionsId
            : parentSessionId !== null
            ? parentSessionId
            : SessionsId !== null
            ? SessionsId
            : null;

    const {
        refetch: refetchReport,
        isLoading: reportLoading,
        isError,
        error,
    } = useQuery<CreateReportDto[]>(
        ['createReport'],
        async () => {
            if (evaId) {
                const reportData = {
                    sessionId: sessionId,
                    generatedReportType: sendVal || generatedReportType,
                    reportName: reportName,
                    prompt: sendVal == 'hit' ? inputVal : prompt,
                    prevPrompt: prvReport !== null ? prvReport.prompt : prevPrompt,
                    newMetrics: selectMetrics ? selectMetrics : [],
                    prevMetrics: prvMetrics,
                    prevProtocols: prvProtocols ? prvProtocols : [],
                    newProtocols: NewSelectedProtocols ? NewSelectedProtocols : [],
                    reportTextType: '.md',
                    reportSection: screen.length > 0 ? screen : 'Oversight ',
                };

                try {
                    dispatch(setReportLoading(true));
                    dispatch(setCreateReport(true));
                    const getReport = await evaService.createReport(evaId, reportData);
                    if (getReport.status === 400) {
                        setReportError(getReport.message || 'Error occurred while fetching the report');
                        dispatch(setReportLoading(false));
                    }
                    if (getReport) {
                        dispatch(setCreateReport(false));
                        setCheckInput(false);
                        dispatch(setReportLoading(false));
                        dispatch(setNewProtocols(getReport.new_protocols));
                        dispatch(setPrvProtocols(getReport.new_protocols));
                        dispatch(setGeneratedReportType(getReport.generatedReportType));
                        dispatch(setNewSelectedProtocols([]));
                        dispatch(setClickProtocol(false));
                        dispatch(setParentSessionId(getReport.parent_session_id ?? getReport.session_id));
                        dispatch(setSessionsReport(getReport));
                        dispatch(setOversightOption(''));
                        setInputVal('');
                        dispatch(setNewMetrics(getReport.new_metrics));
                        dispatch(setPrvMetrics(getReport.new_metrics));
                        setCheckSessionsId(false);
                        setSendVal('');
                    }
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                } catch (error: any) {
                    dispatch(setReportLoading(false));
                    setReportError(error.message || 'Error occurred while fetching the report');
                    dispatch(setNewProtocols([]));
                    dispatch(setPrvProtocols([]));
                    dispatch(setNewMetrics([]));
                    dispatch(setPrvMetrics([]));
                    dispatch(setSelectMetrics([]));
                    setInputVal('');
                    setSendVal('');
                    dispatch(setCreateReport(false));
                    throw error; // Rethrow to let React Query catch it
                }
            }

            return [];
        },
        {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onError: (error: any) => {
                console.error('Error fetching report:', error);
                dispatch(setReportLoading(false));
                setReportError(error.message || 'Error occurred while fetching the report');
            },
            onSettled: () => {
                dispatch(setReportLoading(false));
            },
            retry: false, // Optionally disable retries or customize retry logic
            enabled: false,
        },
    );

    const makeEvaEnableOrDisable = async (toggle: boolean) => {
        if (evaId) {
            const data = await makeEvaEnableDisable({ isEvaEnable: toggle }, organisationId, evaId);
            setTransferSwitch(data.isEvaEnable);
        }
    };
    useEffect(() => {
        if (!isCreateEvaModalOpen) {
            refetch();
        }

        if (isCreateEvaModalOpen) {
            dispatch(setSessionsId(null));
            dispatch(setNewProtocols([]));
            dispatch(setPrvProtocols([]));
            dispatch(setSessionsReport(null));
            dispatch(setReportLoading(false));
            dispatch(setOversightOption(''));
            dispatch(setScreen(''));
            dispatch(setChildScreen(''));
            dispatch(setPrvMetrics([]));
            dispatch(setSelectMetrics([]));
            dispatch(setNewMetrics([]));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCreateEvaModalOpen]);

    //it is for when selected protocol and metrics and report generate then navigate to history or parent report
    useEffect(() => {
        if (
            checkClickProtocol &&
            (childScreen === 'Report' || childScreen === 'ViewHistoryReport' || childScreen === 'History')
        ) {
            refetchReport();
            if (generatedReportType === 'hit' || parentSessionId || SessionsId) {
                dispatch(setChildScreen('ViewHistoryReport'));
            } else {
                dispatch(setChildScreen('Report'));
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkClickProtocol, childScreen, parentSessionId]);

    const handleEnableChange = () => {
        if (evaId !== undefined) {
            setTransferSwitch(!transferSwitch);
            makeEvaEnableOrDisable(!transferSwitch);
        }
    };

    //it is check when version report and also have session id then it is set screen as parentReport and also parentReportId set as sessionId
    useEffect(() => {
        if (checkSessionsId && prvReport !== null) {
            dispatch(setSessionsId(prvReport.session_id));
            dispatch(setChildScreen('Report'));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkSessionsId, prvReport]);

    if (evaId === undefined) {
        return (
            <div className='outset_loader'>
                <AnimatedCircle isThinking={true} />
            </div>
        );
    }

    //this function when prompt sent

    const handleClickOrEnter = () => {
        setSendVal('hit');

        setTimeout(() => {
            if (childScreen === 'History' || (childScreen === 'Report' && generatedReportType == 'guided')) {
                dispatch(setSessionsId(null));
            }
            if (checkInput) {
                refetchReport();
                dispatch(setScreen('Oversight'));
                if (childScreen === 'ViewHistoryReport' || childScreen === 'History' || childScreen === 'Report') {
                    if (childScreen === 'History' && generatedReportType == 'guided') {
                        dispatch(setChildScreen('Report'));
                    } else {
                        dispatch(setChildScreen('ViewHistoryReport'));
                    }
                } else {
                    dispatch(setChildScreen('Report'));
                }
            }
        }, 500);
    };

    //this function when category select
    const fetchOversight = (type: string) => {
        // Update sendVal asynchronously but continue with the local variable `type`
        setSendVal(type);
        dispatch(setPrvPrompt(null));

        if (option.length > 0) {
            setInputVal(option);
        }
        // Use `type` directly instead of waiting for `sendVal` to update
        if (type) {
            setTimeout(() => {
                refetchReport();
                setCheckSessionsId(true);
            }, 300);
        }
    };

    return (
        <div className='w-full mx-auto relative outside_eva'>
            <div className='border border-grey-light p-4 rounded-2xl flex-col eva-publish-planet-bg publish-planet-box bg-white h-full items-center relative flex justify-center'>
                {evaId === undefined && (isLoading || isFetching) ? (
                    <div style={{ height: 770, width: '100%' }}>
                        <div className='outset_loader'>
                            <AnimatedCircle />
                        </div>
                    </div>
                ) : (
                    <>
                        {isMobile ? (
                            ''
                        ) : (
                            <EvaOrbit
                                enablePlanet={transferSwitch}
                                labelShow={labelShow}
                                isCreateEvaModalOpen={isCreateEvaModalOpen}
                                setCreateEvaModalOpen={setCreateEvaModalOpen}
                                evaId={evaId}
                                protocolIndex={protocolIndex}
                                setPublishedEvaExists={setPublishedEvaExists}
                                setDraftEvaExists={setDraftEvaExists}
                            />
                        )}
                    </>
                )}

                <button
                    className='flex items-center switch_btn '
                    onClick={handleEnableChange}
                    disabled={userPermit === ROLES.Member || userPermit === ROLES.Architect}
                >
                    {transferSwitch ? (
                        <div className='switchOn_btn'>
                            <SwitchOn className='switchOn' /> <SwitchOnHover className='switchOnHover' />{' '}
                        </div>
                    ) : (
                        <SwitchOff />
                    )}
                </button>

                <div className='chat_box flex items-center justify-center'>
                    <div className='chat_input flex items-center justify-center'>
                        <input
                            type='text'
                            onChange={(e) => {
                                setInputVal(e.target.value);
                                setCheckInput(true);
                            }}
                            placeholder='Ask me...'
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') handleClickOrEnter();
                            }}
                            value={inputVal}
                        />
                        <button className='absolute ' onClick={handleClickOrEnter}>
                            <SendIcon />
                        </button>
                    </div>
                </div>

                <OversightSummaries
                    setReportError={setReportError}
                    reportError={reportError}
                    prompt={prompt}
                    reportName={reportName}
                    setInputVal={setInputVal}
                    fetchOversight={fetchOversight}
                />
            </div>
        </div>
    );
};
