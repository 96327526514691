import { SetStateAction, useEffect, useState } from 'react';

import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';

import AwardIcon from '../../../../../../../src/assets/icons/AwardIcon.svg';
import AwardIconDisable from '../../../../../../../src/assets/icons/AwardIconDisable.svg';
import CustomDisable from '../../../../../../../src/assets/icons/Custom_disable.svg';
import EmptyProfile from '../../../../../../../src/assets/icons/EmptyProfile.svg';
import GraphLine from '../../../../../../../src/assets/icons/GraphLine.svg';
import GraphLineDisable from '../../../../../../../src/assets/icons/GraphLineDisable.svg';
import ScoreDisable from '../../../../../../../src/assets/icons/ScoreDisable.svg';
import ScoreEnable from '../../../../../../../src/assets/icons/ScoreEnable.svg';
import StarUserDisable from '../../../../../../../src/assets/icons/StarUserDisable.svg';
import StartUserProfile from '../../../../../../../src/assets/icons/StarUserProfile.svg';
import { setClickProtocol, setSelectMetrics } from '../../../../../../Redux/SessionsProtocols';
import { RootState } from '../../../../../../Redux/store';
import { EvaService } from '../../../../../../services/eva/eva.service';
import { getDocumentUrl } from '../../../../../../utils/getDocumentUrl';
import { ProtocolInsights } from '../../../evaAnalytics/components/analyticsChart/types';
import { MetricTypeIcon } from '../MetricsConfig';
import { OptionData } from '../ProtocolTypeList';

interface EvaKpiProps {
    evaId: string;
    toggleKPI: (id: number) => void;
    selectedKPIs: Set<number>;
}

const evaService = new EvaService();
// toggleKPI, kpiRefs, svgRef
export const EvaKpi: React.FC<EvaKpiProps> = ({ evaId, selectedKPIs, toggleKPI }) => {
    const { data: protocolInsights } = useQuery(
        ['ProtocolInsights', evaId],
        async () => {
            if (evaId) {
                const protocolInsight: ProtocolInsights = await evaService.getProtocolInsights(evaId);
                const protocolInsightData = { ...protocolInsight, evaId };
                sessionStorage.setItem(`protocol-${evaId.slice(-8)}`, JSON.stringify(protocolInsightData));
                return protocolInsight;
            }
        },
        {
            enabled: evaId ? true : false,
        },
    );

    const newMetrics = useSelector((state: RootState) => state.SessionsProtocols.newMetrics);

    const dispatch = useDispatch();

    const organizationName = localStorage.getItem('organisationName');

    const [checkToggle, setCheckToggle] = useState<string[]>([]);

    const isOversight = true;

    const childScreen = useSelector((state: RootState) => state.SessionsProtocols.childScreen);

    const ReportLoading = useSelector((state: RootState) => state.SessionsProtocols.ReportLoading);

    const [hoveredId, setHoveredId] = useState<string | null>(null);
    const [tooltipContent, setTooltipContent] = useState<string>('');

    const handleToggle = (value: string) => {
        if (isOversight) {
            // Update checkToggle state
            setCheckToggle((prev) => {
                const updatedCheckToggle = prev.includes(value)
                    ? prev.filter((item) => item !== value) // Remove the value if it exists
                    : [...prev, value]; // Add the value if it does not exist

                return updatedCheckToggle; // Return the updated state
            });
        }
    };
    useEffect(() => {
        // Ensure protocolInsights is defined before proceeding
        if (protocolInsights) {
            // Filter KPIs based on the names present in checkToggle
            const filteredKPIs = Array.isArray(protocolInsights)
                ? protocolInsights.filter((item) => checkToggle.includes(item.name))
                : [];

            // Add the new key isManual to each object in filteredKPIs
            const updatedKPIs = filteredKPIs.map((kpi) => ({
                id: kpi.id,
                value: kpi.value,
                isManual: true,
            }));

            // Dispatch only if updatedKPIs is not empty
            if (updatedKPIs.length > 0) {
                dispatch(setSelectMetrics(updatedKPIs));
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkToggle]);

    useEffect(() => {
        if (newMetrics) {
            // Use a type guard to filter out undefined names
            const namesOnly = newMetrics.map((item) => item.name).filter((name): name is string => name !== undefined);
            setCheckToggle(namesOnly);
        }
    }, [newMetrics]);

    return (
        <div className='absolute w-full h-full'>
            <div className='main_eva_kpi x-scrollbar'>
                <div className='overview_top '>
                    {Array.isArray(protocolInsights) &&
                        protocolInsights.map((obj) => {
                            let kpiIcon, kpiIconNew, tooltipText: SetStateAction<string>, kpiValue, additionalElement;
                            // Assign icon, value, and tooltip text based on KPI name
                            if (obj.isDefault === true) {
                                switch (obj.name) {
                                    case 'Total Engagement Over Time':
                                        kpiIcon =
                                            obj?.value === null
                                                ? ScoreDisable
                                                : obj?.value?.total_percentage
                                                ? ScoreEnable
                                                : ScoreDisable;
                                        kpiValue = obj.value ? `${obj?.value?.total_percentage}%` : '%';
                                        tooltipText =
                                            'This metric gives you an overall understanding of the user base engagement.';
                                        break;

                                    case 'Top Protocol Category':
                                        kpiIconNew =
                                            obj?.value === null
                                                ? null
                                                : obj?.value[0]?.type
                                                ? AwardIcon
                                                : AwardIconDisable;
                                        kpiIcon =
                                            obj?.value === null
                                                ? AwardIconDisable
                                                : (obj?.value !== null &&
                                                      OptionData?.find((item) => item?.name === obj?.value[0]?.type)
                                                          ?.fillIcon) ||
                                                  CustomDisable;
                                        kpiValue =
                                            obj?.value !== null ? obj?.value[0]?.type || 'No Protocol' : 'No Protocol';
                                        tooltipText = `This statistic can help you identify the area of greatest interest or need among ${organizationName} ’s members`;
                                        break;

                                    case 'Most Used Protocol via Phantom':
                                        kpiIcon =
                                            obj?.value === null
                                                ? AwardIconDisable
                                                : obj.value
                                                ? AwardIcon
                                                : AwardIconDisable;
                                        kpiValue = obj?.value ? 'NAMEOFPROTOCOLLOREMIPS' : 'No Protocol';
                                        tooltipText = 'NameofProtocol is garnering the most attention or usage.';
                                        break;

                                    case 'Top Contributor':
                                        kpiIconNew =
                                            obj?.value === null
                                                ? null
                                                : obj?.value?.documentId
                                                ? AwardIcon
                                                : AwardIconDisable;
                                        kpiIcon = obj?.value?.documentId
                                            ? getDocumentUrl(+obj?.value?.documentId)
                                            : EmptyProfile;
                                        kpiValue =
                                            obj?.value?.firstName?.length > 0 ? obj?.value.firstName : 'No Contributor';

                                        tooltipText = `Congrats ${obj.value?.firstName} ${obj.value?.lastName} for all contributions!  🎉
                                 Identify valuable sources of knowledge and expertise.`;
                                        break;

                                    case 'Compliance Score':
                                        kpiIcon =
                                            obj?.value === null
                                                ? ScoreDisable
                                                : obj?.value?.average_score
                                                ? ScoreEnable
                                                : ScoreDisable;
                                        kpiValue = obj.value ? `${obj.value.average_score}%` : '%';
                                        tooltipText = 'Quick pulse on compliance posture.';
                                        break;

                                    case 'Risk Trend':
                                        kpiIcon =
                                            obj?.value === null
                                                ? GraphLineDisable
                                                : obj.value
                                                ? GraphLine
                                                : GraphLineDisable;
                                        kpiValue = obj.value ? `${obj.value}%` : '%';
                                        tooltipText = 'Track Risk Trend of critical incidents over time.';
                                        break;

                                    case 'Latest Member Joining':
                                        kpiIconNew =
                                            obj?.value === null
                                                ? null
                                                : obj.value.firstName.length > 0
                                                ? StartUserProfile
                                                : StarUserDisable;
                                        kpiIcon =
                                            obj?.value === null
                                                ? StarUserDisable
                                                : obj?.value?.documentId
                                                ? getDocumentUrl(+obj?.value?.documentId)
                                                : EmptyProfile;
                                        kpiValue = obj.value.firstName.length > 0 ? obj.value.firstName : 'No Name';

                                        tooltipText = `Welcome ${obj.value?.firstName} ${obj.value?.lastName} 👏! Your latest Member to Join Test5’s Consensus! `;
                                        break;

                                    default:
                                        kpiIcon = null;
                                        kpiValue = null;
                                        tooltipText = '';
                                }
                            }

                            if (obj.isDefault === false) {
                                kpiIcon =
                                    obj?.format === null
                                        ? ScoreDisable
                                        : obj.format
                                        ? MetricTypeIcon(obj.format)
                                        : ScoreDisable;

                                kpiValue = obj ? `${obj.name}` : 'No Name';

                                tooltipText = obj ? `${obj.description}` : 'No description';
                            }

                            return (
                                <div
                                    key={obj.id}
                                    className={` ${checkToggle.includes(obj.name) ? 'active_box' : ''} overview_info ${
                                        obj.name
                                    }`}
                                    onMouseOver={() => {
                                        setHoveredId(obj.id);
                                        setTooltipContent(tooltipText);
                                    }}
                                    data-tooltip-id={obj.id}
                                    onClick={() => {
                                        if (!ReportLoading) {
                                            handleToggle(obj.name);
                                            setTimeout(() => {
                                                if (
                                                    childScreen === 'Report' ||
                                                    childScreen === 'ViewHistoryReport' ||
                                                    childScreen === 'History'
                                                ) {
                                                    dispatch(setClickProtocol(true));
                                                }
                                            }, 500);
                                        }
                                    }}
                                >
                                    {kpiIconNew ? (
                                        <div className='img'>
                                            <img
                                                src={kpiIconNew ? kpiIconNew : undefined}
                                                alt={`${obj.kpiIconNew} Icon`}
                                            />
                                        </div>
                                    ) : (
                                        ''
                                    )}

                                    {kpiIcon ? (
                                        <div className='img'>
                                            <img src={kpiIcon ? kpiIcon : undefined} alt={`${obj.name} Icon`} />
                                        </div>
                                    ) : (
                                        ''
                                    )}

                                    <p className={obj.value ? '' : 'no_content_box'}>{kpiValue}</p>
                                </div>
                            );
                        })}
                </div>
            </div>
            {tooltipContent.length > 0 && (
                <Tooltip
                    id={hoveredId === null ? '' : hoveredId}
                    offset={5}
                    place='top'
                    className='tooltip-box kpi-tooltip'
                >
                    <div className='flex items-center gap-2'>
                        <p className='text-white'> {tooltipContent} </p>
                    </div>
                </Tooltip>
            )}
        </div>
    );
};

export default EvaKpi;
