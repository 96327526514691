import { useState } from 'react';

import { useSelector } from 'react-redux';

import { RootState } from '../../../../../../../Redux/store';
import Report from '../OversightSummaries/Report';
import VersionsHistory from '../OversightSummaries/VersionsHistory';
import InsightSessions from './InsightSessions';

interface InsightScreenProps {
    reportError: string | undefined;
    setReportError: React.Dispatch<React.SetStateAction<string | undefined>>;
    setInputVal: React.Dispatch<React.SetStateAction<string>>;
    prompt: string;
    reportName: string;
}

const InsightScreen: React.FC<InsightScreenProps> = ({
    prompt,
    reportName,
    reportError,
    setReportError,
    setInputVal,
}) => {
    const childScreen = useSelector((state: RootState) => state.SessionsProtocols.childScreen);

    //parent report
    if (childScreen === 'Report' || reportError) {
        return (
            <Report
                reportName={reportName}
                prompt={prompt}
                setReportError={setReportError}
                reportError={reportError}
                setInputVal={setInputVal}
            />
        );
    }

    //history list
    if (childScreen === 'History') {
        return <VersionsHistory />;
    }

    //child report
    if (childScreen === 'ViewHistoryReport') {
        return (
            <Report reportName={reportName} prompt={prompt} setReportError={setReportError} reportError={reportError} />
        );
    }

    return (
        <>
            <InsightSessions />
        </>
    );
};

export default InsightScreen;
