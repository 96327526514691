import { useEffect, useState } from 'react';

import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as DownArrow } from '../../../../../../../../src/assets/icons/DownArrow.svg';
import { ReactComponent as CloseIcon } from '../../../../../../../../src/assets/icons/icon-close.svg';
import { ReactComponent as InsightImg } from '../../../../../../../../src/assets/icons/Insight.svg';
import { ReactComponent as KpiMeter } from '../../../../../../../../src/assets/icons/KpiMeter.svg';
import { ReactComponent as SearchIcon } from '../../../../../../../../src/assets/icons/serachIocn.svg';
import { ReactComponent as SettingIcon } from '../../../../../../../../src/assets/icons/Setting_Icon.svg';
import { ReactComponent as SortIcon } from '../../../../../../../../src/assets/icons/SortArrow_Icon.svg';
import { setChildScreen, setSessionsId } from '../../../../../../../Redux/SessionsProtocols';
import { RootState } from '../../../../../../../Redux/store';
import { EvaService } from '../../../../../../../services/eva/eva.service';
import FormSelect from '../../../../../../profile/Form/FormSelect';
import { msgHistory, reportSummary } from '../../../../evaAnalytics/components/analyticsChart/types';
import { OptionData, protocolTypeIcon } from '../../ProtocolTypeList';
import KpiMetricItem from './KpiMetricItem';
import MarkdownRenderer from './MarkdownRender';
import OversightNoReport from './OversightNoReport';

interface ReportListProps {}

const ReportList: React.FC<ReportListProps> = () => {
    const [sortVal, setSortVal] = useState('');
    const sortType = ['Date', 'Sort A-Z', 'Sort Z-A', 'Recommended', 'Started', 'Not started'];
    const evaService = new EvaService();

    const screen = useSelector((state: RootState) => state.SessionsProtocols.screen);

    const [searchVal, setSearchVal] = useState<string>('');
    const [deleteId, setDeleteId] = useState<string>('');

    const [summary, setSummary] = useState([]);
    const [remainingRecords, setRemainingRecords] = useState([]);

    const evaId = useSelector((state: RootState) => state.SessionsProtocols.EvaId);
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);

    const oversight = screen === 'Oversight';

    const {
        data: summaryData,
        refetch,
        isFetching,
    } = useQuery(
        ['reportSummary', evaId, searchVal, page],
        async () => {
            if (evaId) {
                const reportSummary = await evaService.getReportSummary(evaId, sortVal, searchVal, String(page));

                return reportSummary;
            }
            return [];
        },
        {
            enabled: !!evaId,
        },
    );

    useQuery(
        ['deleteReportData', deleteId, evaId],
        async () => {
            if (deleteId) {
                const reportData = await evaService.DeleteHistory(evaId, [deleteId], true);
                if (reportData.status === 200) {
                    return reportData;
                }
            }
        },
        {
            enabled: !!deleteId,
            onSuccess: () => {
                dispatch(setChildScreen(''));
                refetch();
            },
        },
    );

    //when more records in report list
    useEffect(() => {
        if (summaryData) {
            if (page === 1) {
                // Set the summary and remaining records for the first page
                setSummary(summaryData?.summary.slice(0, 30));
                setRemainingRecords(summaryData?.summary.slice(30));
            } else {
                // Update remaining records
                setRemainingRecords(summaryData?.summary);
                setSummary((prevSummary) => [...prevSummary, ...remainingRecords]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [summaryData, page]);

    useEffect(() => {
        refetch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    useEffect(() => {
        const SummaryList = document.querySelector('.msg_history');

        // Scroll to the top when sortVal is updated

        // Define the scroll handler with debounce
        const handleScroll = debounce(() => {
            if (SummaryList) {
                const { scrollTop, scrollHeight, clientHeight } = SummaryList;
                // Check if the user has scrolled near the bottom of the container
                setPage((pre: number) => {
                    if (summaryData?.totalPage > pre) {
                        if (Math.abs(scrollTop) + clientHeight > scrollHeight - 10) {
                            return pre + 1;
                        }
                    }
                    return pre;
                });
            }
        }, 0);

        // Attach the scroll event listener
        if (SummaryList) {
            SummaryList.addEventListener('scroll', handleScroll);
        }

        // Cleanup: Remove the scroll event listener when the component unmounts
        return () => {
            if (SummaryList) {
                SummaryList.removeEventListener('scroll', handleScroll);
            }
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [summaryData, page, isFetching]); // Add sortVal to dependencies

    // Debounce utility function to limit the rate of function execution
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function debounce<T extends (...args: any[]) => void>(func: T, delay: number): (...args: Parameters<T>) => void {
        let timeoutId: NodeJS.Timeout | null;

        return function (...args: Parameters<T>): void {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(() => {
                func(...args);
            }, delay);
        };
    }

    const handleChangeSortType = (name: string) => {
        setSortVal(name);
        const SummaryList = document.querySelector('.msg_history');
        if (name && SummaryList) {
            SummaryList.scrollTop = 0; // Scroll to the top of the element
            setPage(1);
        }
    };

    const getsSessionId = (sessionId: string, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
        dispatch(setSessionsId(sessionId));
        dispatch(setChildScreen('Report'));
    };

    return (
        <>
            <div className='main_report_list'>
                {summary?.length == 0 && oversight && summaryData?.isReportExist == false ? (
                    ''
                ) : (
                    <>
                        <div className='summaries_information'>
                            <div className='input_box relative'>
                                <SearchIcon />
                                <input
                                    type='text'
                                    onChange={(e) => {
                                        setSearchVal(e.target.value);
                                        setPage(1);
                                    }}
                                    placeholder='Search'
                                />
                            </div>
                        </div>
                    </>
                )}

                <>
                    {summary?.length > 0 && (
                        <div className='sort_box'>
                            <FormSelect
                                startIcon={<SortIcon />}
                                entries={sortType}
                                value={sortVal}
                                onChange={(e) => handleChangeSortType(e)}
                                edit
                                placeholder='Sort by'
                                marginTop={42}
                                endIcon={<DownArrow />}
                                disabled={false}
                            />
                        </div>
                    )}

                    <div
                        className={`${
                            screen && screen === 'Oversight' ? 'oversight_scroll' : ''
                        } msg_history scrollbar`}
                    >
                        {summary && summary?.length > 0 ? (
                            summary?.map((obj: reportSummary, index: number) => {
                                return (
                                    <div className='details mb-5'>
                                        <label className='time'> {obj.time} </label>

                                        {obj.msgHistory?.map((item: msgHistory, index: number | null | undefined) => {
                                            const { metrics, protocols, sessionId, version } = item;
                                            return (
                                                <div
                                                    key={index}
                                                    className={`${
                                                        obj.time !== 'Today' ? 'prev_data' : ''
                                                    } msg_content relative mb-2 cursor-pointer`}
                                                    onClick={(event) => {
                                                        getsSessionId(sessionId, event);
                                                    }}
                                                >
                                                    <div className='cross_btn'>
                                                        <CloseIcon
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                setDeleteId(sessionId);
                                                            }}
                                                        />
                                                    </div>
                                                    <div className='head flex items-center justify-between'>
                                                        <h1>{item.title}</h1>
                                                    </div>
                                                    <p className='dis'>
                                                        <MarkdownRenderer>{item.dis ?? ''}</MarkdownRenderer>
                                                    </p>
                                                    <p className='dis'>Creation date {item.time}</p>
                                                    <div className='kpis_box flex items-center justify-between'>
                                                        <div className='flex gap-2 items-center'>
                                                            {metrics && metrics.length > 0 && (
                                                                <div className='flex meter_box items-center gap-1'>
                                                                    <KpiMeter />
                                                                    <div className='tooltip_box items-start'>
                                                                        {metrics.map((metric, index) => (
                                                                            <KpiMetricItem
                                                                                key={index}
                                                                                metric={metric}
                                                                                OptionData={OptionData}
                                                                            />
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {protocols && protocols?.length > 0 && (
                                                                <div className='protocol_icon relative flex items-center justify-center'>
                                                                    <SettingIcon />
                                                                    <div className='tooltip_box items-start'>
                                                                        {protocols?.map((protocol, index) => (
                                                                            <p
                                                                                key={index}
                                                                                className='flex gap-1 items-center'
                                                                            >
                                                                                <img
                                                                                    src={protocolTypeIcon(
                                                                                        protocol.protocolType,
                                                                                    )}
                                                                                    alt={protocol.protocolType}
                                                                                />
                                                                                <span>
                                                                                    {protocol.name
                                                                                        ? protocol.name
                                                                                        : 'Unknown Protocol'}
                                                                                </span>
                                                                            </p>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className='versions'>
                                                            <p>{version} versions</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            })
                        ) : (
                            <>
                                <>
                                    {oversight ? (
                                        <>
                                            {summaryData?.isReportExist == false ? (
                                                <OversightNoReport
                                                    title={<h1>No Report</h1>}
                                                    dis={
                                                        <h6>
                                                            {' '}
                                                            Ask <span>E V A </span> some insight.{' '}
                                                        </h6>
                                                    }
                                                />
                                            ) : (
                                                <p className='no_data'> {summaryData?.msg} </p>
                                            )}
                                        </>
                                    ) : (
                                        <div className='data_unavailable text-center '>
                                            <div className='img_box flex items-center justify-center'>
                                                <InsightImg />
                                            </div>
                                            <h1>No Insight Available</h1>
                                            <h6>Try to Interact with E V A</h6>
                                        </div>
                                    )}
                                </>
                            </>
                        )}
                    </div>
                </>
            </div>
        </>
    );
};

export default ReportList;
