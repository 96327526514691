import { useState } from 'react';

import { useSelector } from 'react-redux';

import { useSubscriptionDetail } from '../../../../../../../contexts/subscription.context';
import { RootState } from '../../../../../../../Redux/store';
import { SubScriptionType } from '../../../types';
import Report from '../OversightSummaries/Report';
import VersionsHistory from '../OversightSummaries/VersionsHistory';
import Oversight from './Oversight';

interface OversightScreenProps {
    fetchOversight: (type: string) => void;
    prompt: string;
    reportName: string;
    reportError: string | undefined;
    setReportError: React.Dispatch<React.SetStateAction<string | undefined>>;
    setInputVal: React.Dispatch<React.SetStateAction<string>>;
}

const OversightScreen: React.FC<OversightScreenProps> = ({
    prompt,
    reportName,
    fetchOversight,
    reportError,
    setReportError,
    setInputVal,
}) => {

    const childScreen = useSelector((state: RootState) => state.SessionsProtocols.childScreen);
    const subscription = useSubscriptionDetail();
    const isShield = subscription?.productName === SubScriptionType?.SHIELD;

    //parent report
    if (isShield ) {
        <Oversight fetchOversight={fetchOversight} />;
    } else {
        if (childScreen === 'Report' || reportError) {
            return (
                <Report
                    setReportError={setReportError}
                    prompt={prompt}
                    reportName={reportName}
                    setInputVal={setInputVal}
                    reportError={reportError}
                />
            );
        }
    }

    //history list
    if (isShield ) {
        <Oversight fetchOversight={fetchOversight} />;
    } else {
        if (childScreen === 'History') {
            return <VersionsHistory />;
        }
    }

    if (isShield ) {
        <Oversight fetchOversight={fetchOversight} />;
    } else {
        if (childScreen === 'ViewHistoryReport') {
            return (
                <Report
                    reportError={reportError}
                    reportName={reportName}
                    prompt={prompt}
                />
            );
        }
    }

    return (
        <>
            <Oversight fetchOversight={fetchOversight} />
        </>
    );
};

export default OversightScreen;
