import { useState } from 'react';

import clsx from 'clsx';

import { ReactComponent as CrossIcon } from '../../../../../../assets/icons/icon-cross-fill.svg';
import { ReactComponent as EditIcon } from '../../../../../../assets/icons/icon-edit.svg';
import { ReactComponent as LockFilledIcon } from '../../../../../../assets/icons/icon-lock-filled.svg';
import { ReactComponent as LockIcon } from '../../../../../../assets/icons/icon-lock-outline.svg';
import { ReactComponent as ThumbsDown } from '../../../../../../assets/icons/icon-thumbs-down.svg';
import { ReactComponent as ThumbsUp } from '../../../../../../assets/icons/icon-thumbs-up.svg';
import EvaDialog from '../EvaDialog.component';
import { clearSvg } from './MetricCard.component';
import { FeedbackStatus } from './metricsType';

const MetricCardAction = ({
    setToggle,
    metricName,
    lockMetric,
    toggleLock,
    handleDelete,
    isDefault = false,
    likeFeedback,
    dislikeFeedback,
    feedbackStatus,
    addFeedback,
    deleteFeedback,
    showFeedback = true,
    updateMetric,
}: {
    setToggle: React.Dispatch<React.SetStateAction<boolean>>;
    metricName: string;
    lockMetric: boolean;
    toggleLock?: (value: boolean) => void;
    handleDelete: () => void;
    isDefault: boolean;
    likeFeedback: number;
    dislikeFeedback: number;
    feedbackStatus?: FeedbackStatus;
    addFeedback?: (status: FeedbackStatus) => void;
    deleteFeedback?: () => void;
    showFeedback?: boolean;
    updateMetric?: () => void;
}) => {
    const [like, setLike] = useState(feedbackStatus === FeedbackStatus.Like);
    const [dislike, setDislike] = useState(feedbackStatus === FeedbackStatus.Dislike);
    const [likeCount, setLikeCount] = useState(likeFeedback);
    const [dislikeCount, setDislikeCount] = useState(dislikeFeedback);
    const [isHovered, setIsHovered] = useState(false);
    const [lock, setLock] = useState(lockMetric);
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);

    const handleLike = () => {
        if (like) {
            setLike(false);
            setLikeCount(likeCount - 1);
            if (deleteFeedback) deleteFeedback();
        } else {
            setLike(true);
            setLikeCount(likeCount + 1);
            if (addFeedback) addFeedback(FeedbackStatus.Like);
            if (dislike) {
                setDislike(false);
                setDislikeCount(dislikeCount - 1);
            }
        }
    };

    const handleDislike = () => {
        if (dislike) {
            setDislike(false);
            setDislikeCount(dislikeCount - 1);
            if (deleteFeedback) deleteFeedback();
        } else {
            setDislike(true);
            setDislikeCount(dislikeCount + 1);
            if (addFeedback) addFeedback(FeedbackStatus.Dislike);
            if (like) {
                setLike(false);
                setLikeCount(likeCount - 1);
            }
        }
    };

    return (
        <>
            <EvaDialog
                disabled={false}
                open={isOpenDeleteModal}
                setClose={setIsOpenDeleteModal}
                onConfirm={() => {
                    setToggle(false);
                    handleDelete();
                }}
                title={`Delete ${metricName}`}
                content={
                    <div>
                        You're about to permanently delete this metric. This action cannot be undone. Are you sure you
                        want to proceed?
                    </div>
                }
                confirmButtonText='Delete Metric'
                buttonVariant='rejectRed'
            />
            <div className='flex justify-between mt-2'>
                <div className={clsx('flex space-x-4 items-center', !showFeedback && 'opacity-50')}>
                    <div className='flex space-x-2 items-center text-white'>
                        <button
                            className={clsx('thumbs-up', like && 'thumbs-up-selected')}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleLike();
                            }}
                            data-title='Like'
                            disabled={!showFeedback}
                        >
                            <ThumbsUp className='w-3 h-3' stroke='white' stroke-opacity='0.75' />
                        </button>
                        <p>{likeCount}</p>
                    </div>
                    <div className='flex space-x-2 items-center text-white'>
                        <button
                            className={clsx('thumbs-down', dislike && 'thumbs-down-selected')}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleDislike();
                            }}
                            data-title='Dislike'
                            disabled={!showFeedback}
                        >
                            <ThumbsDown className='w-3 h-3' stroke='white' stroke-opacity='0.75' />
                        </button>
                        <p>{dislikeCount}</p>
                    </div>
                </div>

                <div className='w-full flex space-x-3 items-center justify-end'>
                    <button
                        className={clsx('lock-btn', lock && 'lock-btn-selected')}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                        onClick={(e) => {
                            e.stopPropagation();
                            setLock(!lock);
                            if (toggleLock) toggleLock(!lock);
                        }}
                        data-title={lock ? 'Locked' : 'Lock'}
                    >
                        {isHovered || lock ? (
                            <LockFilledIcon className='w-2.5 h-2.5' fill='white' fill-opacity='1' />
                        ) : (
                            <LockIcon className='w-2.5 h-2.5' fill='white' fill-opacity='0.75' />
                        )}
                    </button>
                    <button
                        className={clsx('edit-icon', isDefault && 'opacity-50 cursor-not-allowed')}
                        data-title='Edit'
                        onClick={(e) => {
                            clearSvg();
                            e.stopPropagation();
                            if (updateMetric) updateMetric();
                        }}
                        disabled={isDefault}
                    >
                        <EditIcon className='w-3 h-3' fill='white' fill-opacity='0.75' />
                    </button>
                    <div className='vertical-line' />
                    <button
                        className={clsx('cross-icon', isDefault && 'opacity-50 cursor-not-allowed')}
                        onClick={(e) => {
                            e.stopPropagation();
                            setIsOpenDeleteModal(true);
                        }}
                        data-title='Delete'
                        disabled={isDefault}
                    >
                        <CrossIcon className='w-3 h-3' fill='white' fill-opacity='0.75' />
                    </button>
                </div>
            </div>
        </>
    );
};

export default MetricCardAction;
