import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { ReactComponent as Document } from '../../../../../../../src/assets/icons/Document.svg';
import { ReactComponent as SessionsGroup } from '../../../../../../../src/assets/icons/Sessions_group.svg';
import {
    setChildScreen,
    setNewMetrics,
    setNewProtocols,
    setOversightOption,
    setPrvMetrics,
    setPrvProtocols,
    setReportLoading,
    setScreen,
    setSelectMetrics,
    setSessionsId,
    setSessionsReport,
} from '../../../../../../Redux/SessionsProtocols';
import { RootState, useAppDispatch } from '../../../../../../Redux/store';
import InsightScreen from './Insight/InsightScreen';
import OversightScreen from './Oversight/OversightScreen';
import './OversightSummaries/OversightSummaries.css';

interface OversightSummariesProps {
    reportError: string | undefined;
    setReportError: React.Dispatch<React.SetStateAction<string | undefined>>;
    fetchOversight: (type: string) => void;
    prompt: string;
    reportName: string;
    setInputVal: React.Dispatch<React.SetStateAction<string>>;
}

const OversightSummaries: React.FC<OversightSummariesProps> = ({
    fetchOversight,
    reportError,
    setInputVal,
    prompt,
    reportName,
    setReportError,
}) => {
    const screen = useSelector((state: RootState) => state.SessionsProtocols.screen);
    const childScreen = useSelector((state: RootState) => state.SessionsProtocols.childScreen);
    const [showScreen, setShowScreen] = useState(false);
    const reportLoading = useSelector((state: RootState) => state.SessionsProtocols.ReportLoading);

    const dispatch = useAppDispatch();

    const openScreen = (screenName: string) => {
        if (screen === screenName) {
            setShowScreen(!showScreen);
        } else {
            dispatch(setScreen(screenName));
            setShowScreen(true);
        }
    };

    //if we have not any screen open like Insight or OverSight
    useEffect(() => {
        if (!showScreen) {
            dispatch(setSessionsId(null));
            dispatch(setNewProtocols([]));
            dispatch(setPrvProtocols([]));
            dispatch(setNewMetrics([]));
            dispatch(setSelectMetrics([]));
            dispatch(setPrvMetrics([]));
            dispatch(setSessionsReport(null));
            dispatch(setReportLoading(false));
            dispatch(setOversightOption(''));
            setShowScreen(false);
            dispatch(setScreen(''));

            dispatch(setChildScreen(''));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showScreen]);

    //it is used for when screen is report List or history report list
    useEffect(() => {
        if (screen?.length > 0) {
            openScreen(screen);
            setShowScreen(true);
        }
        if (childScreen !== 'Report' && childScreen !== 'ViewHistoryReport') {
            dispatch(setNewProtocols([]));
            dispatch(setNewMetrics([]));
        }

        if (screen === '') {
            setShowScreen(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [screen.length, childScreen, screen]);

    return (
        <>
            <div className={`${showScreen ? '' : 'show_screen'} oversight_summaries`}>
                <div className='sidebar_tab'>
                    <ul className='mt-3'>
                        <li
                            className={` ${screen === 'Insight' ? 'active' : ''} relative`}
                            onClick={() => {
                                if (!reportLoading) {
                                    openScreen('Insight');
                                }
                            }}
                        >
                            <div className='tooltip_box'>
                                <p> Insight Sessions </p>
                            </div>
                            <SessionsGroup />
                        </li>
                        <li
                            className={` ${screen === 'Oversight' ? 'active' : ''} relative`}
                            onClick={() => {
                                if (!reportLoading) {
                                    openScreen('Oversight');
                                }
                            }}
                        >
                            <div className='tooltip_box'>
                                <p> Oversight </p>
                            </div>
                            <Document />
                        </li>
                    </ul>
                </div>

                {showScreen && (
                    <div className='inside_screen'>
                        {screen === 'Insight' && (
                            <InsightScreen
                                setReportError={setReportError}
                                setInputVal={setInputVal}
                                prompt={prompt}
                                reportName={reportName}
                                reportError={reportError}
                            />
                        )}

                        {screen === 'Oversight' && (
                            <OversightScreen
                                setReportError={setReportError}
                                reportError={reportError}
                                prompt={prompt}
                                reportName={reportName}
                                setInputVal={setInputVal}
                                fetchOversight={fetchOversight}
                            />
                        )}
                    </div>
                )}
            </div>
        </>
    );
};

export default OversightSummaries;
