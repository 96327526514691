import { useState } from 'react';

import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';

import { appInsights } from '../../../../../AppInsights';
import { useSubscriptionDetail } from '../../../../../contexts/subscription.context';
import { useStore } from '../../../../../store';
import { CustomLoader } from '../../../../basic/CustomeLoader/CustomLoader';
import { StarIcon } from '../../../../basic/StarIcon.component';
import { Switch } from '../../../../basic/Switch.component';
import Nodata from '../../../../Nodata';
import { useServices } from '../../../../ServiceProvider';
import { SubScriptionType } from '../../eva/types';
import { ReactComponent as EmptyGraph } from './../../../../../assets/icons/empty_graph_navi.svg';
import { ReactComponent as InfoIcon } from './../../../../../assets/icons/info_Iocn.svg';
import ComplianceMonitoring from './../../../../../assets/icons/Navi_ComplianceMonitoring.png';
import TopLevelDomains from './../../../../../assets/icons/navi_topLevel_domain.png';
import VisitSite from './../../../../../assets/icons/navi_visit_site.png';
import ComplianceMontioring from './ComplianceMontioring.component';
import TopDomain from './TopDomain.component';
import WebsiteCategoryTable from './WebsiteCategoryTable.component';

const Navi = () => {
    const [naviOff, setNaviOff] = useState<boolean>(false);

    const { id } = useParams<{ id: string }>();
    const store = useStore();

    const { organisation } = useServices();

    const subscription = useSubscriptionDetail();
    const isShieldSubscription = subscription?.productName === SubScriptionType.SHIELD;

    const { isLoading: loading, data: data } = useQuery(['is-navi-exist', id], async () => {
        try {
            const data = await organisation.isNaviDataExists(id);
            return data;
        } catch (e) {
            console.error(e);
            if (e instanceof Error) {
                appInsights.trackException({ error: e });
            }
        }
    });

    const { isLoading: naviStatusLoading } = useQuery(['navi-status', id], async () => {
        try {
            const data = await organisation.getNaviStatus(id);
            setNaviOff(data);
        } catch (e) {
            console.error(e);
            if (e instanceof Error) {
                appInsights.trackException({ error: e });
            }
        }
    });

    if (loading || naviStatusLoading) {
        return <CustomLoader />;
    }

    const organisationName = store.spaces.currentSelectedWorkSpaceInfo.workspaceName;

    const handleNaviStatusChange = async (checked: boolean) => {
        setNaviOff(checked);
        await organisation.updateNaviStatus(id, checked);
    };

    return (
        <div>
            {!isShieldSubscription && !data?.isNaviDataExist && !data?.isUserEnabled ? (
                <Nodata
                    button={
                        <Link to={`/organisation/${id}/members`}>
                            <button className='navi_btn'>Review Members</button>
                        </Link>
                    }
                    title='No Navigation Analysis Yet'
                    description={
                        <p>
                            Make sure <span>{organisationName}</span> Members enabled <b>EVA</b>
                        </p>
                    }
                    EmptyImage={EmptyGraph}
                />
            ) : (
                <>
                    <div className='navi_left_side scroll_div '>
                        <div
                            className='w-full flex justify-end px-10 mb-4'
                            style={{
                                paddingRight: '3rem',
                            }}
                        >
                            <Switch onChange={(checked) => handleNaviStatusChange(checked)} checked={naviOff} />
                        </div>
                        <div className='w-full flex gap-3  '>
                            <div className='relative max-w-3xl navi_box '>
                                {isShieldSubscription ? (
                                    <>
                                        <img src={VisitSite} alt='VisitSite' />
                                        <div className='isShield_start'>
                                            <Link to={`/organisation/${id}/change-tier`}>
                                                <StarIcon blackIcon={true} isChangePlusIcon={true} isHover={true} />
                                            </Link>
                                        </div>
                                    </>
                                ) : (
                                    <WebsiteCategoryTable />
                                )}
                            </div>
                            <div
                                className='space-y-4 md:px-0 px-md-5 md:mt-0 mt-4'
                                style={{
                                    minWidth: '292px',
                                }}
                            >
                                {isShieldSubscription ? (
                                    <>
                                        <div className='relative'>
                                            <div className='navi_empty_heading flex gap-2 items-center'>
                                                <h1> Top Level Domains</h1>{' '}
                                                <InfoIcon className='cursor-pointer info_icon' />
                                                <div className='navi_info_box'>
                                                    {' '}
                                                    TLDs provide insights into the general nature of websites visited.{' '}
                                                </div>
                                            </div>

                                            <img src={TopLevelDomains} alt='TopLevelDomains' />
                                            <div className='isShield_start'>
                                                <Link to={`/organisation/${id}/change-tier`}>
                                                    <StarIcon blackIcon={true} isChangePlusIcon={true} isHover={true} />
                                                </Link>
                                            </div>
                                        </div>
                                        <div className='relative'>
                                            <div className='navi_empty_heading flex gap-2 items-center'>
                                                <h1>Compliance Monitoring </h1>{' '}
                                                <InfoIcon className='cursor-pointer info_icon' />
                                                <div className='navi_info_box'>
                                                    Add restricted domains and monitor non-compliant visits.{' '}
                                                </div>
                                            </div>
                                            <div className='isShield_start'>
                                                <Link to={`/organisation/${id}/change-tier`}>
                                                    <StarIcon blackIcon={true} isChangePlusIcon={true} isHover={true} />
                                                </Link>
                                            </div>
                                            <img src={ComplianceMonitoring} alt='ComplianceMonitoring' />
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <TopDomain />
                                        <ComplianceMontioring />
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default Navi;
