import '../Trends/Trends.css';
import { useState, useRef, useEffect } from 'react';

import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

import shieldTrend from '../../../../../../src/assets/icons/empty_trents.png';
import flag from '../../../../../../src/assets/icons/flag.svg';
import { ReactComponent as GreaterThansymbol } from '../../../../../../src/assets/icons/GreaterThansymbol.svg';
import { ReactComponent as LessThansymbol } from '../../../../../../src/assets/icons/LessThansymbol.svg';
import { appInsights } from '../../../../../AppInsights';
import { useSubscriptionDetail } from '../../../../../contexts/subscription.context';
import { useOrganisationForm } from '../../../../../hooks/useOrganisation';
import { RootState, useAppDispatch } from '../../../../../Redux/store';
import { setSelectedIds, setSelectedProtocols, setSelectMetricProtocol } from '../../../../../Redux/TrendMetric';
import { EvaService } from '../../../../../services/eva/eva.service';
import { CustomLoader } from '../../../../basic/CustomeLoader/CustomLoader';
import { StarIcon } from '../../../../basic/StarIcon.component';
import { ReactComponent as SearchIcon } from '../.././../../../../src/assets/icons/serachIocn.svg';
import { OptionData } from '../../eva/components/ProtocolTypeList';
import {
    GraphProtocol,
    GraphProtocolTypePercentage,
    SubScriptionType,
    EvaGraphAnalyticsEvaList,
    ProtocolTypes,
} from '../../eva/types';
import ActiveEvaSelect from '../risk/ActiveEvaSelect.component';
import EmptyRisk from '../risk/EmptyRisk.component';
import { ReactComponent as InfoIcon } from './../../../../../assets/icons/info_Iocn.svg';
import Metrics from './Metrics';
import ProtocolTrendChart from './ProtocolTrendChart';
import TrendsFilter from './TrendsFilter';

const Trends = () => {
    const subscription = useSubscriptionDetail();
    const [evaId, setEvaId] = useState('');
    const [titleList, setTitleList] = useState<EvaGraphAnalyticsEvaList[]>();
    const { getEvaListByOrganisationId } = useOrganisationForm();
    const { id: organisationId } = useParams<{ id: string }>();
    const [timeFilter, setTimeFilter] = useState<string>('Day'); // Initial state for time filter
    const [search, setSearch] = useState('');
    const [filterType, setFilterType] = useState<string[]>([]);
    const [selectedTrendProtocols, setSelectedTrendProtocol] = useState<string[]>([]);
    const [showSearch, setShowSearch] = useState(false);
    const [selectPreviousProcol, setSelectPreviousProcol] = useState<string[]>([]);
    const isSelectMetric = useSelector((state: RootState) => state.TrendMetric.selectedIds);
    const selectMetricProtocol = useSelector((state: RootState) => state.TrendMetric.selectMetricProtocol);
    const dispatch = useAppDispatch();

    const isShield = subscription?.productName === SubScriptionType?.SHIELD;
    const evaService = new EvaService();

    const { id } = useParams<{ id: string }>();
    useEffect(() => {
        if (selectedTrendProtocols) {
            dispatch(setSelectedProtocols(selectedTrendProtocols));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTrendProtocols]);

    const { isFetching, isLoading } = useQuery(['evaData', organisationId], async () => {
        try {
            const eva = await getEvaListByOrganisationId(organisationId);
            if (eva?.length > 0) {
                const publishedEva = eva.find((obj: EvaGraphAnalyticsEvaList) => obj.isPublished);
                const evaIdToSet = publishedEva ? publishedEva.id : eva[0]?.id;
                setEvaId(evaIdToSet);
            }

            setTitleList(eva);
        } catch (e) {
            console.error(e);
            if (e instanceof Error) {
                appInsights.trackException({ error: e });
            }
        }
    });

    useEffect(() => {
        if (titleList && !evaId) {
            if (!evaId) {
                setEvaId(titleList && titleList?.length > 0 ? titleList[0]?.id : '');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [evaId, titleList]);

    const {
        data: triggerData,
        isLoading: loading,
        isFetching: fetching,
    } = useQuery(['eva-protocol', evaId, timeFilter], () => {
        try {
            if (evaId) {
                return evaService.getEvaProtocolLog(evaId, timeFilter);
            }
        } catch (e) {
            console.error(e);
            if (e instanceof Error) {
                appInsights.trackException({ error: e });
            }
        }
    });
    const {
        data: triggerList,
        isLoading: loadingList,
        isFetching: fetchingList,
    } = useQuery(['eva-protocol-list', evaId, filterType, search], () => {
        try {
            if (evaId) {
                return evaService.getEvaProtocolList(evaId, filterType, search);
            }
        } catch (e) {
            console.error(e);
            if (e instanceof Error) {
                appInsights.trackException({ error: e });
            }
        }
    });

    const HandleEvaChange = (id: string) => {
        setEvaId(id);
        if (titleList) {
            const evaName = titleList?.find((item) => item.id === id);
        }
    };

    const toggleProtocol = (protocolName: string, index: number) => {
        setSelectPreviousProcol((prev) =>
            prev.includes(protocolName) ? prev.filter((name) => name !== protocolName) : [...prev, protocolName],
        );
        if (selectMetricProtocol.length === 0) {
            setSelectedTrendProtocol((prev) =>
                prev.includes(protocolName) ? prev.filter((name) => name !== protocolName) : [...prev, protocolName],
            );
        }
    };

    useEffect(() => {
        if (selectMetricProtocol && triggerData) {
            const matchedProtocols = triggerList?.protocols
                .filter((item: { protocolName: string }) => selectMetricProtocol.includes(item.protocolName))
                .map((item: { protocolName: string }) => item.protocolName);
            setSelectedTrendProtocol(matchedProtocols);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectMetricProtocol]);

    useEffect(() => {
        if (selectPreviousProcol && !selectedTrendProtocols.length && !selectMetricProtocol.length) {
            setSelectedTrendProtocol(selectPreviousProcol);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectPreviousProcol, selectedTrendProtocols]);

    const toggleFilterType = (type: string) => {
        setFilterType((prev) => (prev.includes(type) ? prev.filter((name) => name !== type) : [...prev, type]));
    };

    const getFilteredProtocolsList = () => {
        if (triggerData) {
            return triggerData.protocols.filter((protocol: GraphProtocol) =>
                selectedTrendProtocols.includes(protocol.protocolName),
            );
        }
        return [];
    };

    if (isFetching || isLoading) {
        return <CustomLoader />;
    }

    if (isShield) {
        return (
            <div className='trends_box scroll_div'>
                <div className='trend_empty '>
                    <div className='empty_select ' />

                    <div className='relative'>
                        <div className='navi_empty_heading flex gap-2 items-center'>
                            <h1> Protocols Triggering Frequency </h1> <InfoIcon className='cursor-pointer info_icon' />
                            <div className='navi_info_box'>
                                Triggering Frequency will display how often each Protocol is flagged the most during
                                interactions.
                            </div>
                        </div>
                        <img className='shield_trend' src={shieldTrend} alt='' />
                        <div className='isShield_start  '>
                            <Link to={`/organisation/${id}/change-tier`}>
                                <StarIcon blackIcon={true} isChangePlusIcon={true} isHover={true} />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <>
                <div className='trends_box scroll_div'>
                    {titleList && titleList?.length > 0 && (
                        <div className='flex justify-between items-center'>
                            <ActiveEvaSelect
                                evaId={evaId}
                                handleEvaChange={HandleEvaChange}
                                titleList={titleList}
                                isShield={false}
                            />
                            <TrendsFilter setTimeFilter={setTimeFilter} timeFilter={timeFilter} />
                        </div>
                    )}

                    {titleList && titleList.length > 0 ? (
                        <div className='trends_protocol'>
                            <div className='trends_head mb-5 pb-2 flex justify-between items-center'>
                                <h2 className='flex items-center gap-1'>
                                    Protocols Triggering Frequency <InfoIcon className='ml-2 cursor-pointer' />{' '}
                                    <div className='navi_info_box'>
                                        Triggering Frequency will display how often each Protocol is flagged the most
                                        during interactions.
                                    </div>
                                </h2>
                                <div className='trend_top_box  w-full flex  items-center flex-wrap justify-end'>
                                    <div
                                        className={` search_box d-flex pr-3 items-center relative ${
                                            showSearch ? 'show_search' : ''
                                        }`}
                                    >
                                        <div className='searchIocn' onClick={() => setShowSearch(!showSearch)}>
                                            <SearchIcon />
                                        </div>
                                        <input
                                            type='text'
                                            onChange={(e) => setSearch(e.target.value)}
                                            placeholder='Search'
                                        />
                                    </div>

                                    <div className='type_box flex items-center filter_box gap-2  '>
                                        {OptionData?.map((item, index) => {
                                            const isActive = filterType.includes(item.name);
                                            return (
                                                <button
                                                    className={`relative ${isActive ? 'active' : ''}`}
                                                    onClick={() => toggleFilterType(item?.name)}
                                                >
                                                    <div className='tooltip_box'> {item.name} </div>
                                                    <img src={item?.icon} />
                                                </button>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>

                            <div className='trends_protocol_list'>
                                <div
                                    className={`main_grid_box scrollbar ${
                                        isSelectMetric.length > 0 ? 'less_height' : ''
                                    }`}
                                >
                                    {loadingList || fetchingList ? (
                                        <CustomLoader />
                                    ) : (
                                        <>
                                            {triggerList &&
                                            triggerList?.protocols &&
                                            triggerList?.protocols.length > 0 ? (
                                                <div className='grid_box '>
                                                    {triggerList?.protocols.map(
                                                        (data: GraphProtocol, index: number) => {
                                                            const type =
                                                                typeof data.type === 'object' && 'error' in data.type
                                                                    ? 'Custom'
                                                                    : data.type;

                                                            const getIcon = OptionData.find(
                                                                (item) => item.name === type,
                                                            );
                                                            const isActive = selectedTrendProtocols.includes(
                                                                data.protocolName,
                                                            );

                                                            let typeClass = '';
                                                            if (type === ProtocolTypes.Policy) {
                                                                typeClass = 'policy_color';
                                                            } else if (type === ProtocolTypes.Custom) {
                                                                typeClass = 'custom_color';
                                                            } else if (type === ProtocolTypes.GoodPractice) {
                                                                typeClass = 'good_practice_color';
                                                            } else if (type === ProtocolTypes.Critical) {
                                                                typeClass = 'critical_thinking_color';
                                                            } else if (type === ProtocolTypes.GoldStandard) {
                                                                typeClass = 'gold_standard_color';
                                                            } else if (type === ProtocolTypes.Metrics) {
                                                                typeClass = 'metrics_color';
                                                            }

                                                            const TotalTriggerNo = triggerData?.protocols?.find(
                                                                (item: GraphProtocol) =>
                                                                    item.protocolName == data.protocolName,
                                                            )?.totalTrigger;

                                                            return (
                                                                <div
                                                                    key={index} // Always include a unique key for list items
                                                                    className={`box relative ${
                                                                        isActive ? `${typeClass} active_box` : typeClass
                                                                    }`}
                                                                    onClick={() => {
                                                                        if (selectMetricProtocol.length > 0) {
                                                                            dispatch(setSelectMetricProtocol([]));
                                                                            dispatch(setSelectedIds([]));
                                                                        }
                                                                        toggleProtocol(data.protocolName, index);
                                                                    }}
                                                                >
                                                                    <h1>
                                                                        <img src={getIcon?.icon} alt='winnercup' />
                                                                        <span>{data.protocolName}</span>
                                                                    </h1>

                                                                    <div className='flex gap-3'>
                                                                        <div className='trigger_box flex items-center justify-around'>
                                                                            <img src={flag} alt='flag' />
                                                                            <span className='triggerIcon'>
                                                                                {data?.triggerState === '<' ? (
                                                                                    <LessThansymbol />
                                                                                ) : (
                                                                                    <GreaterThansymbol />
                                                                                )}
                                                                            </span>
                                                                            <span>{data.triggerNumber}</span>
                                                                        </div>

                                                                        <div
                                                                            data-tooltip-id='trigger_tooltips'
                                                                            className='flex trigger_no items-center justify-center  '
                                                                        >
                                                                            {TotalTriggerNo}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        },
                                                    )}
                                                    <Tooltip
                                                        id='trigger_tooltips'
                                                        offset={5}
                                                        place='top'
                                                        className='tooltip-box'
                                                    >
                                                        <p> Trigger </p>
                                                    </Tooltip>
                                                </div>
                                            ) : (
                                                <div className='flex items-center justify-center w-full h-full'>
                                                    {' '}
                                                    <h6> No Protocol Found </h6>{' '}
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>

                                {/* <div className='graph_line'>
                                    {triggerList &&
                                        triggerList.percentageChange &&
                                        triggerList.percentageChange.length > 0 &&
                                        (() => {
                                            const filteredTriggerList = triggerList.percentageChange.filter(
                                                (item: GraphProtocolTypePercentage) => item.percentage > 0,
                                            );

                                            return filteredTriggerList.map((item: GraphProtocolTypePercentage) => {
                                                const color = OptionData.find((items) => items.name === item.type);
                                                const getProtocolData = OptionData.find(
                                                    (data) => data.name === item.type,
                                                );
                                                return (
                                                    <div
                                                        key={item.type} // Unique key for each item
                                                        className='graph_line_box relative'
                                                        style={{
                                                            background: color?.lineColor,
                                                            height: `${item.percentage}%`,
                                                        }}
                                                    >
                                                        <div className='graph_info'>
                                                            <p className='flex gap-1 items-center'>
                                                                <img
                                                                    className='mt-1'
                                                                    src={getProtocolData?.icon}
                                                                    alt=''
                                                                />
                                                                <span
                                                                    style={{
                                                                        backgroundImage: getProtocolData?.txtColor,
                                                                    }}
                                                                >
                                                                    {' '}
                                                                    {item.type}
                                                                </span>
                                                            </p>
                                                            <div>
                                                                <span>{item.count}</span>
                                                                <span>{item.percentage.toFixed(2)}%</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            });
                                        })()}
                                </div> */}
                            </div>

                            <div
                                className={` line_chart_box relative mb-5  ${
                                    isSelectMetric.length > 0 ? 'less_height' : ''
                                }`}
                            >
                                <ProtocolTrendChart
                                    filterFormat={triggerData?.dateRange}
                                    timeFilter={timeFilter}
                                    selectedProtocolsList={getFilteredProtocolsList()}
                                    averageProtocolTrigger={triggerData?.averageProtocolTrigger}
                                />
                                <div className='w-full flex items-center justify-center filter_name'>
                                    <span>{timeFilter}</span>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className='no_trend relative trends_protocol'>
                                <div className='trends_head mb-5 pb-2 flex justify-between items-center'>
                                    <h2 className='flex items-center gap-1'>
                                        Protocols Triggering Frequency <InfoIcon className='ml-2 cursor-pointer' />{' '}
                                        <div className='navi_info_box'>
                                            Triggering Frequency will display how often each Protocol is flagged the
                                            most during interactions.
                                        </div>
                                    </h2>
                                </div>

                                <EmptyRisk
                                    title='No Trends Available'
                                    description='Deploy a Protocol first'
                                    buttonName='Add a Protocol'
                                />
                            </div>
                        </>
                    )}
                </div>
            </>

            <Metrics evaId={evaId} />
        </>
    );
};

export default Trends;
