import { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';
import { useSelector } from 'react-redux';

import { RootState } from '../../../../../../Redux/store';
import { categoryList } from '../../../eva/components/metrics/metricsType';

//default bar chart parameters
const MetricBarChart = () => {
    const chartData = useSelector((state: RootState) => state.TrendMetric.chartData);
    const maxValue = Math.max(...chartData.flatMap((item) => item.data)) + 20;

    const colorStops = chartData.map((item) => {
        const colorObj = categoryList.find((obj) => obj.key === item.category);
        return {
            offset: 50,
            color: colorObj ? colorObj.color : 'rgba(0, 0, 0, 1)',
            opacity: 0.85,
        };
    });

    //default options
    const options: ApexOptions = {
        chart: {
            type: 'bar',
            width: '100%',
            toolbar: {
                show: false,
            },
            stacked: false,
            zoom: {
                enabled: false,
            },
        },
        dataLabels: {
            enabled: false,
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '20%',
                borderRadius: 6,
                borderRadiusApplication: 'end',
                borderRadiusWhenStacked: 'all',
            },
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [0, 100],
                colorStops: colorStops,
            },
        },
        xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Mar', 'Apr', 'May', 'Jun'],
            labels: {
                show: true,
                style: {
                    colors: 'rgba(15, 16, 68, 0.75)',
                    fontSize: '10px',
                    fontFamily: 'Poppins',
                    fontWeight: '400',
                },
            },
            position: 'bottom',
            axisTicks: {
                show: true,
            },
            axisBorder: {
                show: false,
                offsetX: -3,
                offsetY: -3,
            },
        },
        yaxis: {
            labels: {
                show: true,
                style: {
                    colors: 'rgba(15, 16, 68, 0.75)',
                    fontSize: '10px',
                    fontFamily: 'Poppins',
                    fontWeight: '400',
                },
                offsetX: 0,
                offsetY: -1,
                formatter: (value: number): string => Math.round(value).toString(),
            },
            max: maxValue,
            min: 0,
            axisBorder: {
                show: true,
                color: '#0f104412',
                offsetX: -3,
                offsetY: -2,
            },
        },
        tooltip: {
            followCursor: true,
            enabled: true,
            shared: true,
            intersect: false,
            custom: ({ series, seriesIndex, dataPointIndex }) => {
                const value = series[seriesIndex][dataPointIndex];
                const seriesName = chartData[seriesIndex].name;
                return `
                    <div style="padding: 7px; background-color: rgba(0, 20, 255, 0.75); border-radius: 5px; color: #fff;">
                        <p style="font-size: 11px;">${seriesName}</p>
                     <p style="font-size: 11px;">   Value: ${value} </p>
                    </div>
                `;
            },
        },
        legend: {
            show: false,
        },
        grid: {
            show: true,
            borderColor: '#0f104412',
            strokeDashArray: 0,
            padding: {
                left: 10,
                right: 30,
            },
        },
    };

    // format for series data
    // const series = [{ name: 'Metric A', data: [50, 15, 20, 25, 30, 100 , 20, 25, 30, 100] }];
    return (
        <div style={{ height: '100%', width: '100%' }}>
            <Chart options={options} series={chartData} type='bar' height={300} />
        </div>
    );
};

export default MetricBarChart;
