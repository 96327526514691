import { makeStyles } from '@material-ui/core';
import { Skeleton } from '@mui/material';

import { ReactComponent as CrossIcon } from '../../../../../../assets/icons/icon-cross-fill.svg';
import { ReactComponent as EditIcon } from '../../../../../../assets/icons/icon-edit.svg';
import { ReactComponent as FavIcon } from '../../../../../../assets/icons/icon-fav.svg';
import { ReactComponent as LockIcon } from '../../../../../../assets/icons/icon-lock-outline.svg';
import { ReactComponent as ThumbsDown } from '../../../../../../assets/icons/icon-thumbs-down.svg';
import { ReactComponent as ThumbsUp } from '../../../../../../assets/icons/icon-thumbs-up.svg';
import { Switch } from '../../../../../basic/Switch.component';

const useStyles = makeStyles((theme) => ({
    skeletonMetricCategory: {
        fontSize: '16px',
        borderRadius: '4px',
        background: '#FFFFFF0D',
    },
    skeletonMetricName: {
        fontSize: '16px',
        borderRadius: '50px',
        background: '#FFFFFF0D',
    },
    skeletonMetricDescription: {
        fontSize: '16px',
        borderRadius: '50px',
        background: '#FFFFFF0D',
    },
}));

const MetricCardSkeleton = ({ expanded, checked = false }: { expanded?: boolean; checked?: boolean }) => {
    const classes = useStyles();
    return (
        <div className='metric-skeleton-card w-full p-3'>
            <div className='w-full flex justify-between'>
                <div className='flex space-x-2 items-center'>
                    <Skeleton variant='rectangular' className={classes.skeletonMetricCategory} width={12} height={12} />
                    <Skeleton variant='rectangular' className={classes.skeletonMetricName} width={190} height={32} />
                </div>
                <div className='flex justify-end space-x-2 items-center'>
                    <button className={'fav-btn opacity-50 pointer-events-none'}>
                        <FavIcon className='w-3 h-3' stroke='#FFFFFFBF' fill='#FFFFFF' fillOpacity={0} />
                    </button>
                    <Switch
                        className='opacity-50'
                        checked={checked}
                        onChange={() => console.log('')}
                        checkedColor='bg-metric bg-opacity-100'
                        uncheckedColor='bg-white bg-opacity-10'
                    />
                </div>
            </div>
            {expanded && (
                <div>
                    <div className='space-y-2 mt-2'>
                        <Skeleton
                            variant='rectangular'
                            className={classes.skeletonMetricDescription}
                            width='100%'
                            height={10}
                        />
                        <Skeleton
                            variant='rectangular'
                            className={classes.skeletonMetricDescription}
                            width='100%'
                            height={10}
                        />
                        <Skeleton
                            variant='rectangular'
                            className={classes.skeletonMetricDescription}
                            width='50%'
                            height={10}
                        />
                    </div>
                    <div className='flex justify-between mt-2 opacity-50 items-center pointer-events-none'>
                        <div className='flex space-x-4 items-center'>
                            <div className='flex space-x-2 items-center text-white'>
                                <ThumbsUp className='w-3 h-3' stroke='white' strokeOpacity='0.75' />
                                <p className='text-xs'>0</p>
                            </div>
                            <div className='flex space-x-2 items-center text-white'>
                                <ThumbsDown className='w-3 h-3' stroke='white' strokeOpacity='0.75' />
                                <p className='text-xs'>0</p>
                            </div>
                        </div>
                        <div className='flex space-x-3 items-center'>
                            <button className='lock-btn'>
                                <LockIcon className='w-3 h-3' fill='white' fillOpacity='0.75' />
                            </button>
                            <EditIcon className='w-3 h-3' fill='white' fillOpacity='0.75' />
                            <div className='vertical-line' />
                            <CrossIcon className='w-3 h-3' fill='white' fillOpacity='0.75' />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MetricCardSkeleton;
