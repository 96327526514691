import { Switch as SwitchHeadless } from '@headlessui/react';
import clsx from 'clsx';

import { ReactComponent as LockIcon } from '../../assets/icons/icon-lock-fill.svg';

export function Switch(props: {
    checked?: boolean;
    onChange: (checked: boolean) => void;
    className?: string;
    disabled?: boolean;
    locked?: boolean;
    icon?: string;
    text?: string;
    checkedColor?: string;
    uncheckedColor?: string;
    checkedThumbColor?: string;
    uncheckedThumbColor?: string;
    showTooltip?: boolean;
}) {
    return (
        <div>
            <div className='w-full h-full  flex items-center  '>
                {props.icon && <img src={props.icon} alt='' />}
                {props.text && <p className='text-sm ml-2 mr-6 text-darker opacity-75'>{props.text}</p>}

                {/* Use props.text instead of text */}
                <SwitchHeadless
                    disabled={props.disabled}
                    //@ts-ignore
                    checked={props.checked}
                    onChange={() => props.onChange(!props.checked)}
                    className={clsx(
                        `${
                            props.locked
                                ? 'bg-warning-400 bg-opacity-50'
                                : props.checked
                                ? props.checkedColor
                                    ? props.checkedColor
                                    : 'bg-blue'
                                : props.uncheckedColor
                                ? props.uncheckedColor
                                : 'bg-gray-200'
                        }
                relative items-center justify-between inline-flex flex-shrink-0 h-4 w-8 
                rounded-full transition-colors ease-in-out duration-200 border-none
                focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75 `,
                        props.className,
                        props.disabled ? 'cursor-not-allowed' : 'cursor-pointer ',
                        'switch-headless',
                    )}
                    data-title={props.showTooltip ? (props.checked ? 'Enabled' : 'Disabled') : null}
                >
                    <span className='sr-only'>User setting</span>
                    {props.locked ? (
                        <LockIcon className='w-2 h-2 ml-2' fill='#0F1044BF' />
                    ) : (
                        <span
                            aria-hidden='true'
                            className={clsx(
                                `${props.checked ? 'translate-x-4' : 'translate-x-1'}
                pointer-events-none inline-block h-3 w-3 rounded-full shadow-lg transform ring-0 
                transition ease-in-out duration-200`,
                                props.checked
                                    ? props.checkedThumbColor || 'bg-white'
                                    : props.uncheckedThumbColor || 'bg-white',
                            )}
                        />
                    )}
                </SwitchHeadless>
            </div>
        </div>
    );
}
