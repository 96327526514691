import { useState } from 'react';

import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import Menu from '@mui/material/Menu';

import { ReactComponent as FilterIcon } from '../../../../../../assets/icons/icon-filter.svg';
import { SortType } from './metricsType';

const SortComponent = ({ handleSort }: { handleSort: (sortType: SortType) => void }) => {
    const [anchor, setAnchor] = useState<null | HTMLElement>(null);

    const open = Boolean(anchor);
    const handleClose = () => {
        setAnchor(null);
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchor(event.currentTarget);
    };

    return (
        <div>
            <button
                className='flex items-center text-sm text-white text-opacity-75 space-x-2 hover:text-opacity-100 sort-btn'
                onClick={(e) => handleClick(e)}
            >
                <FilterIcon className='w-3 h-3' fill='#FFFFFF' fillOpacity={0.75} />
                <p>Sort by</p>
                <KeyboardArrowDownOutlinedIcon className='sort-icon' />
            </button>
            <Menu
                id='metric-sort-menu'
                anchorEl={anchor}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    className: 'sort-menu-list',
                }}
                disableAutoFocus
            >
                <button
                    className='w-full flex text-sm p-2 text-darker text-opacity-75 hover:bg-primary-darker hover:bg-opacity-3'
                    onClick={() => {
                        handleSort(SortType.Date);
                        handleClose();
                    }}
                >
                    <p>Date</p>
                </button>
                <button
                    className='w-full flex text-sm p-2 text-darker text-opacity-75 hover:bg-primary-darker hover:bg-opacity-3'
                    onClick={() => {
                        handleSort(SortType.AtoZ);
                        handleClose();
                    }}
                >
                    <p>Sort A-Z</p>
                </button>
                <button
                    className='w-full flex text-sm p-2 text-darker text-opacity-75 hover:bg-primary-darker hover:bg-opacity-3'
                    onClick={() => {
                        handleSort(SortType.ZtoA);
                        handleClose();
                    }}
                >
                    <p>Sort Z-A</p>
                </button>
                <button
                    className='w-full flex text-sm p-2 text-darker text-opacity-75 hover:bg-primary-darker hover:bg-opacity-3'
                    onClick={() => {
                        handleSort(SortType.Category);
                        handleClose();
                    }}
                >
                    <p>Categories</p>
                </button>
            </Menu>
        </div>
    );
};

export default SortComponent;
