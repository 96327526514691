import { Dispatch, SetStateAction } from 'react';

interface TrendsFilterProps {
    timeFilter: string;
    setTimeFilter: Dispatch<SetStateAction<string>>;
}

//time filter for trends
const TrendsFilter: React.FC<TrendsFilterProps> = ({ setTimeFilter, timeFilter }) => {
    return (
        <div className='filter_box flex items-center '>
            <div
                className={`${timeFilter === 'Day' ? 'active' : ''} relative filter_btn`}
                onClick={() => setTimeFilter('Day')}
            >
                D<div className='tooltip_box'>Today</div>
            </div>
            <div
                className={`${timeFilter === 'Week' ? 'active' : ''} relative filter_btn`}
                onClick={() => setTimeFilter('Week')}
            >
                W<div className='tooltip_box'>Last 7 Days</div>
            </div>
            <div
                className={`${timeFilter === 'Month' ? 'active' : ''} relative filter_btn`}
                onClick={() => setTimeFilter('Month')}
            >
                M<div className='tooltip_box'>Last 30 Days</div>
            </div>
            <div
                className={`${timeFilter === 'Year' ? 'active' : ''} relative filter_btn`}
                onClick={() => setTimeFilter('Year')}
            >
                Y<div className='tooltip_box'>Last Year</div>
            </div>
            <div
                className={`${timeFilter === 'All' ? 'active' : ''} relative filter_btn`}
                onClick={() => setTimeFilter('All')}
            >
                A<div className='tooltip_box'> All Time</div>
            </div>
        </div>
    );
};

export default TrendsFilter;
