import { ChangeEvent, useState } from 'react';
import { forwardRef, ComponentProps } from 'react';

import clsx from 'clsx';

export type TextAreaProps = {
    disabled?: boolean;
    value?: string;
    required?: boolean;
    error?: boolean;
    placeholder?: string;
    onBlur?: () => void;
    onChange?: (value: string) => void;
    className?: string;
    left?: React.ReactNode;
    right?: React.ReactNode;
    textareaProps?: ComponentProps<'textarea'>;
    containerRef?: React.Ref<HTMLDivElement>;
    label?: string;
    edit?: boolean;
    maxLength?: number;
    showCounter?: boolean;
    rounded?: boolean;
    onKeyPress?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
    errorMessage?: string;
    background?: string;
    border?: string;
    textColor?: string;
    id: string;
};

export const Description = forwardRef<HTMLTextAreaElement, TextAreaProps>(
    (
        {
            onBlur,
            onChange,
            value: realValue,
            error,
            placeholder,
            required,
            right,
            className,
            textareaProps,
            containerRef,
            label,
            edit = true,
            maxLength,
            disabled,
            showCounter,
            rounded,
            onKeyPress,
            errorMessage,
            background = 'bg-white',
            border = 'border border-blue-dark border-opacity-10 hover:border-opacity-20',
            textColor = 'text-blue-dark',
            id,
        },
        ref,
    ) => {
        const [count, setCount] = useState<number>(0);

        const [value, setValue] = useState(realValue);
        const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
            const newValue = e.target.value;
            setValue(newValue);
            setCount(newValue.length);
            if (onChange) onChange(e.target.value);
        };
        return (
            <div className={clsx('text-sm flex-grow font-medium')}>
                {label && (
                    <p className='text-darker text-opacity-75 mb-2'>
                        {label}
                        {required && <span className='text-error'>*</span>}
                    </p>
                )}
                {edit ? (
                    <div
                        ref={containerRef}
                        className={clsx(
                            'flex transition-shadow overflow-hidden w-full',
                            'focus-within:outline-none',
                            border,
                            disabled ? 'bg-secondary-100 text-neutral-400' : background,
                            error && 'ring-error-900 ring-2',
                            className,
                            rounded ? 'rounded-full' : 'rounded-xl',
                        )}
                    >
                        <textarea
                            id={id}
                            ref={ref}
                            {...textareaProps}
                            onBlur={onBlur}
                            onChange={(e) => handleChange(e)}
                            onKeyPress={(e) => onKeyPress && onKeyPress(e)}
                            placeholder={placeholder}
                            maxLength={maxLength}
                            required={required}
                            disabled={disabled}
                            className={clsx(
                                `focus:outline-none flex-grow p-4 w-full bg-transparent cursor-pointer rounded-xl`,
                                disabled && 'cursor-not-allowed text-neutral-400',
                                textareaProps?.className,
                                textColor,
                            )}
                            value={value}
                        />
                        {right}
                    </div>
                ) : (
                    <p className='text-normal'>{(value && value) || placeholder}</p>
                )}
                <div className='flex'>
                    {errorMessage && <p className='text-xs mt-2 text-error whitespace-nowrap'>{errorMessage}</p>}
                    {showCounter && (
                        <div className={clsx('w-full text-right font-normal mt-2', textColor)}>
                            {maxLength && maxLength - count}
                        </div>
                    )}
                </div>
            </div>
        );
    },
);
