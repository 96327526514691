import clsx from 'clsx';

import { ReactComponent as BinaryIcon } from './icons/icon-binary.svg';
import { ReactComponent as ForecastIcon } from './icons/icon-forecast.svg';
import { ReactComponent as GrowthIcon } from './icons/icon-growth.svg';
import { ReactComponent as IDIcon } from './icons/icon-ID.svg';
import { ReactComponent as IndexIcon } from './icons/icon-index.svg';
import { ReactComponent as CountIcon } from './icons/icon-number.svg';
import { ReactComponent as PercentageIcon } from './icons/icon-percentage.svg';
import { ReactComponent as RankIcon } from './icons/icon-rank.svg';
import { ReactComponent as RatioIcon } from './icons/icon-ratio.svg';
import { ReactComponent as RiskIcon } from './icons/icon-risk.svg';
import { ReactComponent as ScoreIcon } from './icons/icon-score.svg';
import { ReactComponent as TrendDownIcon } from './icons/icon-trend-down.svg';
import { ReactComponent as TrendStableIcon } from './icons/icon-trend-stable.svg';
import { ReactComponent as TrendUpIcon } from './icons/icon-trend-up.svg';
import { ReactComponent as VelocityIcon } from './icons/icon-velocity.svg';
import { FormatList } from './metricsType';

export const getMetricTypeIcon = (name: string): React.FunctionComponent<React.SVGProps<SVGSVGElement>> => {
    switch (true) {
        case name === FormatList.Percentage:
            return PercentageIcon;
        case name === FormatList.Ratio:
            return RatioIcon;
        case name === FormatList.Score:
            return ScoreIcon;
        case name === FormatList.Binary:
            return BinaryIcon;
        case name === 'Trend Increase':
            return TrendUpIcon;
        case name === 'Trend Decrease':
            return TrendDownIcon;
        case name === 'Trend Stable':
            return TrendStableIcon;
        case name === FormatList.Index:
            return IndexIcon;
        case name === FormatList.Rank:
            return RankIcon;
        case name === 'ID':
            return IDIcon;
        case name === 'Count':
            return CountIcon;
        case name === FormatList.GrowthRate:
            return GrowthIcon;
        case name === FormatList.Velocity:
            return VelocityIcon;
        case name === FormatList.Forecast:
            return ForecastIcon;
        case name === 'Risk Low' || name === 'Risk Medium' || name === 'Risk High' || name === FormatList.RiskLevel:
            return RiskIcon;
        default:
            return PercentageIcon;
    }
};

export const getMetricTypeColor = (name: string): string => {
    switch (true) {
        case name === FormatList.Percentage:
            return 'metric-percentage';
        case name === FormatList.Ratio:
            return 'metric-ratio';
        case name === FormatList.Score:
            return 'metric-score';
        case name === FormatList.Binary:
            return 'metric-binary';
        case name === 'Trend Increase':
            return 'metric-trend';
        case name === 'Trend Decrease':
            return 'metric-trend';
        case name === 'Trend Stable':
            return 'metric-trend-stable';
        case name === FormatList.Index:
            return 'metric-index';
        case name === FormatList.Rank:
            return 'metric-rank';
        case name === 'ID':
            return 'metric-id';
        case name === 'Count':
            return 'metric-count';
        case name === FormatList.GrowthRate:
            return 'metric-growth';
        case name === FormatList.Velocity:
            return 'metric-velocity';
        case name === FormatList.Forecast:
            return 'metric-forecast';
        case name === 'Risk Low':
            return 'metric-risk-low';
        case name === 'Risk Medium':
            return 'metric-risk-medium';
        case name === 'Risk High':
            return 'metric-risk-high';
        default:
            return 'metric-percentage';
    }
};

const MetricType = ({
    type,
}:{ 
    type: string;
}) => {
    const IconComponent = getMetricTypeIcon(type);
    const className = getMetricTypeColor(type);
    return (
        <div className={clsx('metric-icon-container', className)}>
            <IconComponent className='w-2.5 h-2.5' />
        </div>
    );
};

export default MetricType;
