import { XCircleIcon } from '@heroicons/react/solid';
import { CheckIcon } from '@heroicons/react/solid';

export type IconAlertProps = {
    message: string;
    className?: string;
    type?: 'error' | 'warning' | 'success';
};

export const IconAlert = ({ message, className = '', type }: IconAlertProps) => {
    return (
        <div className={`text-sm flex ${className}`}>
            {type === 'success' ? (
                <div className='flex-shrink flex flex-row space-x-4'>
                    <CheckIcon color='#373BBA' className='h-5' />
                    <h1 className='text-blue-dark'>{message}</h1>
                </div>
            ) : type === 'warning' ? (
                <div className=' warning_alert text-warning-100 flex-shrink flex flex-row space-x-4 p-2'>
                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='#664d03' className='size-6'>
                        <path
                            fill-rule='evenodd'
                            d='M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z'
                            clip-rule='evenodd'
                        />
                    </svg>
                    <h1 className='ml-1'>{message}</h1>
                </div>
            ) : (
                <div className='bg-warning-100 text-warning-100 flex-shrink flex flex-row space-x-4 p-2'>
                    <XCircleIcon color='red' className='h-5' />
                    <h1 className='text-warning'>{message}</h1>
                </div>
            )}
        </div>
    );
};
