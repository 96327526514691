import { makeAutoObservable } from 'mobx';

import { DocumentsStore } from './DocumentsStore';
import { MetricStore } from './MetircStore';
import { OrganisationStore } from './OrganisationStore';
import { SessionStore } from './SessionStore';
import { SignInStore } from './SignInStore';
import { SignUpStore } from './SignUpStore';
import { SpacesStore } from './SpacesStore';
import { SubscriptionStore } from './SubscriptionsStore';
import { UserStore } from './UserStore';

type AccountDeletionDate = {
    accountDeletionDate: string | null;
};

export class Store {
    user: UserStore = new UserStore(null);
    accountDeletion?: AccountDeletionDate;
    signUpStore = new SignUpStore();
    signInStore = new SignInStore();
    subscriptions = new SubscriptionStore();
    documents = new DocumentsStore();
    spaces = new SpacesStore();
    session = new SessionStore();
    organisation = new OrganisationStore();
    metric = new MetricStore();
    initiated = false;
    showMobileMenu = false;
    extensionStatus = false;

    constructor() {
        makeAutoObservable(this);
    }

    get isAccountDeleted() {
        return !!this.accountDeletion?.accountDeletionDate;
    }

    setInitiated(initiated: boolean) {
        this.initiated = initiated;
    }

    setAccountDeletionDate(deletionDate: AccountDeletionDate | undefined) {
        this.accountDeletion = deletionDate;
    }

    setShowMobileMenu(showSideMenu: boolean) {
        this.showMobileMenu = showSideMenu;
    }

    setExtensionStatus(status: boolean) {
        this.extensionStatus = status;
    }
}
