import { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';

//default Guage chart parameters
const MetricGaugeChart = () => {
    const initialData = [70];

    //default options
    const options: ApexOptions = {
        chart: {
            type: 'radialBar',
            width: '100%',
            toolbar: {
                show: false,
            },
            stacked: false,
            zoom: {
                enabled: false,
            },
        },
        dataLabels: {
            enabled: false,
        },
        plotOptions: {
            radialBar: {
                startAngle: -90,
                endAngle: 90,
                track: {
                    background: '#e7e7e7',
                    margin: 5,
                },
                dataLabels: {
                    name: {
                        show: false,
                    },
                    value: {
                        offsetY: 0,
                        fontSize: '22px',
                    },
                },
            },
        },
        grid: {
            padding: {
                top: -10,
            },
        },
        fill: {
            colors: ['#00b8ff'],
        },
        labels: ['Average Results'],

        stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
        },

        xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Mar', 'Apr', 'May', 'Jun'],
            labels: {
                show: true,
                style: {
                    colors: 'rgba(15, 16, 68, 0.75)',
                    fontSize: '10px',
                    fontFamily: 'Poppins',
                    fontWeight: '400',
                },
            },
            position: 'bottom',
            axisTicks: {
                show: false,
            },
            axisBorder: {
                show: false,
                offsetX: -3,
                offsetY: -3,
            },
        },
        yaxis: {
            labels: {
                show: false,
                style: {
                    colors: 'rgba(15, 16, 68, 0.75)',
                    fontSize: '10px',
                    fontFamily: 'Poppins',
                    fontWeight: '400',
                },
                offsetX: 0,
                offsetY: -1,
                formatter: (value: number): string => Math.round(value).toString(),
            },
            max: 100,
            min: 0,
            axisBorder: {
                show: true,
                color: '#0f104412',
                offsetX: -3,
                offsetY: -2,
            },
        },
        tooltip: {
            followCursor: true,
            enabled: true,
            shared: true,
            intersect: false,
        },
        legend: {
            show: false,
        },
    };

    return (
        <div style={{ height: '100%', width: '100%' }} className='flex items-center justify-center'>
            <Chart options={options} series={initialData} type='radialBar' height={300} />
        </div>
    );
};

export default MetricGaugeChart;
