import AwardIcon from '../../../../../../../../src/assets/icons/AwardIcon.svg';
import AwardIconDisable from '../../../../../../../../src/assets/icons/AwardIconDisable.svg';
import CustomDisable from '../../../../../../../../src/assets/icons/Custom_disable.svg';
import EmptyProfile from '../../../../../../../../src/assets/icons/EmptyProfile.svg';
import GraphLine from '../../../../../../../../src/assets/icons/GraphLine.svg';
import GraphLineDisable from '../../../../../../../../src/assets/icons/GraphLineDisable.svg';
import ScoreDisable from '../../../../../../../../src/assets/icons/ScoreDisable.svg';
import ScoreEnable from '../../../../../../../../src/assets/icons/ScoreEnable.svg';
import StarUserDisable from '../../../../../../../../src/assets/icons/StarUserDisable.svg';
import { getDocumentUrl } from '../../../../../../../utils/getDocumentUrl';
import { Metric } from '../../../../evaAnalytics/components/analyticsChart/types';

interface KpiMetricItemProps {
    metric: Metric;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    OptionData?: any[];
}

const KpiMetricItem: React.FC<KpiMetricItemProps> = ({ metric, OptionData }) => {
    let kpiIcon, kpiValue;

    switch (metric.name) {
        case 'totalEngagement':
            kpiIcon =
                metric?.value === null ? ScoreDisable : metric?.value?.total_percentage ? ScoreEnable : ScoreDisable;
            kpiValue = metric.value ? `${metric?.value?.total_percentage}%` : '%';
            break;

        case 'topProtocol':
            kpiIcon =
                metric?.value === null
                    ? AwardIconDisable
                    : (metric?.value !== null &&
                          OptionData?.find((item) => item?.name === metric?.value[0]?.type)?.fillIcon) ||
                      CustomDisable;
            kpiValue = metric?.value !== null ? metric?.value[0]?.type || 'No Protocol' : 'No Protocol';

            break;

        case 'mostUsedProtocol':
            kpiIcon = metric?.value === null ? AwardIconDisable : metric.value ? AwardIcon : AwardIconDisable;
            kpiValue = metric?.value ? 'NAMEOFPROTOCOLLOREMIPS' : 'No Protocol';

            break;

        case 'topContributor':
            kpiIcon = metric?.value?.documentId ? getDocumentUrl(+metric?.value?.documentId) : EmptyProfile;
            kpiValue = metric?.value?.firstName?.length > 0 ? metric?.value.firstName : 'No Contributor';

            break;

        case 'complianceScore':
            kpiIcon = metric?.value === null ? ScoreDisable : metric?.value?.average_score ? ScoreEnable : ScoreDisable;
            kpiValue = metric.value ? `${metric.value.average_score}%` : '%';

            break;

        case 'riskTrend':
            kpiIcon = metric?.value === null ? GraphLineDisable : metric.value ? GraphLine : GraphLineDisable;
            kpiValue = metric.value ? `${metric.value}%` : '%';
            break;

        case 'latestMember':
            kpiIcon =
                metric?.value === null
                    ? StarUserDisable
                    : metric?.value?.documentId
                    ? getDocumentUrl(+metric?.value?.documentId)
                    : EmptyProfile;
            kpiValue = metric.value.firstName.length > 0 ? metric.value.firstName : 'No Name';

            break;

        default:
            kpiIcon = null;
            kpiValue = null;
            break;
    }

    return (
        <p className='flex gap-1 items-center'>
            <img src={kpiIcon ? kpiIcon : undefined} alt={`${metric.name} Icon`} />
            <span>{kpiValue ? kpiValue : ''}</span>
        </p>
    );
};

export default KpiMetricItem;
