import ReportList from '../OversightSummaries/ReportList';

interface InsightSessionsProps {}

const InsightSessions: React.FC<InsightSessionsProps> = () => {
    return (
        <div className='h-full sessions'>
            <div className='summaries_heading flex justify-between items-center'>
                <div className='flex items-center gap-3'>
                    <h1> Sessions </h1>
                </div>
            </div>

            <ReportList  />
        </div>
    );
};

export default InsightSessions;
