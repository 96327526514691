import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ProtocolState {
    selectedProtocols: string[];
    selectedIds: string[];
    chartData: LineChart[];
    selectMetricProtocol: string[];
}

interface LineChart {
    name: string;
    id: string;
    data: number[];
    category: string;
}

const initialState: ProtocolState = {
    selectedProtocols: [],
    selectedIds: [],
    chartData: [],
    selectMetricProtocol: [],
};

const TrendMetric = createSlice({
    name: 'TrendMetric',
    initialState,
    reducers: {
        // handle selected protocols; when a protocol is selected,
        // associated metrics are also selected automatically
        setSelectedProtocols: (state, action: PayloadAction<string[]>) => {
            state.selectedProtocols = action.payload;
        },
        // handle selected metrics; when a metric is selected,
        // associated protocols are also selected automatically
        setSelectMetricProtocol: (state, action: PayloadAction<string[]>) => {
            state.selectMetricProtocol = action.payload;
        },
        // handle selected metric IDs; used to select a metric ID
        // and check if it has already been selected
        setSelectedIds: (state, action: PayloadAction<string[]>) => {
            state.selectedIds = action.payload;
        },
        // manage chart data; dynamically sets data based on the selected metric ID
        setChartData: (state, action: PayloadAction<LineChart[]>) => {
            state.chartData = action.payload; // Updated to set lineChartData correctly
        },
    },
});

export const {
    setSelectedProtocols,
    setSelectMetricProtocol,
    setSelectedIds,
    setChartData, // Exported setLineChartData
} = TrendMetric.actions;

export default TrendMetric.reducer;
