import { ApexOptions } from 'apexcharts';
import Chart from 'react-apexcharts';
import { useSelector } from 'react-redux';

import { RootState } from '../../../../../../Redux/store';

//default line chart parameters
const MetricLineChart = () => {
    const chartData = useSelector((state: RootState) => state.TrendMetric.chartData);
    const maxValue = Math.max(...chartData.flatMap((item) => item.data));

    //default options
    const options: ApexOptions = {
        chart: {
            type: 'area',
            width: '100%',
            toolbar: {
                show: false,
            },
            stacked: false,
            zoom: {
                enabled: false,
            },
            brush: {
                enabled: false,
            },
        },
        colors: ['#5A49FE'],
        dataLabels: {
            enabled: false,
        },
        markers: {
            size: 0,
            strokeWidth: 0,
            hover: {
                size: 3,
            },
            shape: 'circle',
        },
        stroke: {
            curve: 'smooth',
            width: 1,
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.3,
                opacityTo: 0.9,
                stops: [0, 90, 100],
            },
        },

        xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Mar', 'Apr', 'May', 'Apr'],
            labels: {
                style: {
                    colors: 'rgba(15, 16, 68, 0.75)',
                    fontSize: '10px',
                    fontFamily: 'Poppins',
                    fontWeight: '400',
                },
            },
            position: 'bottom',
            axisTicks: {
                show: true,
            },
            axisBorder: {
                show: true,
                offsetX: 0,
                offsetY: 0,
            },
        },
        yaxis: {
            title: {
                offsetX: -5,
                text: 'Percentage (%)',
                style: {
                    color: 'rgba(15, 16, 68, 0.75)',
                    fontSize: '10px',
                    fontWeight: '500',
                    fontFamily: 'Poppins',
                },
            },

            labels: {
                style: {
                    colors: 'rgba(15, 16, 68, 0.75)',
                    fontSize: '10px',
                    fontFamily: 'Poppins',
                    fontWeight: '400',
                },
                offsetX: -3,
                offsetY: -1,
                formatter: (value: number): string => Math.round(value).toString(),
            },
            max: maxValue,
            min: 0,
            axisBorder: {
                show: true,
                color: '#0f104412',
                offsetX: 0,
                offsetY: -2,
            },
        },
        tooltip: {
            followCursor: true,
            enabled: true,
            shared: true,
            intersect: false,
            custom: ({ series, seriesIndex, dataPointIndex }) => {
                const value = series[seriesIndex][dataPointIndex];
                const seriesName = chartData[seriesIndex].name;
                return `
                    <div style="padding: 7px; background-color: rgba(0, 20, 255, 0.75); border-radius: 5px; color: #fff;">
                        <p style="font-size : 10px;">${seriesName}</p>
                         <p style="font-size : 10px;"> Value: ${value} </p>
                    </div>
                `;
            },
        },
        legend: {
            show: false,
        },
        grid: {
            show: true,
            borderColor: '#0f104412',
            strokeDashArray: 0,
            padding: {
                left: 10,
                right: 30,
            },
        },
    };

    return (
        <div style={{ height: '100%', width: '100%' }}>
            <Chart
                options={options}
                series={chartData} // Provide the series data here
                type='area'
                height={300}
            />
        </div>
    );
};

export default MetricLineChart;
