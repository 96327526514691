import { makeAutoObservable } from 'mobx';

export class MetricStore {
    metricGraphProtocol: string[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    setMetricGraphProtocol(protocol: string) {
        // Check if the protocol already exists in the array
        if (!this.metricGraphProtocol.includes(protocol)) {
            this.metricGraphProtocol.push(protocol);
        }
    }

    get getMetricGraphProtocol() {
        return this.metricGraphProtocol;
    }
}
