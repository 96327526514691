import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
    SessionReport,
    SourceProtocols,
} from '../components/organisation/copilotOrganisation/evaAnalytics/components/analyticsChart/types';

// Define the protocol type
interface Protocol {
    name: string;
    protocolType: string;
    isManual: boolean;
}

interface Metric {
    uuid?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value?: any;
    name?: string;
    description?: string;
    category?: string;
    format?: string;
    feedbackRatio?: string;
    isManual: boolean;
    sourceProtocols?: SourceProtocols;
    aggregatedValue?: number;
}

// Define the initial state type
interface ProtocolState {
    PrvProtocols: Protocol[];
    SessionsId: string | null;
    ParentSessionId: string | null;
    EvaId: string; // Change this to null for proper initialization
    SessionsReport: SessionReport | null;
    NewProtocols: Protocol[];
    newSelectProtocols: Protocol[];
    ReportLoading: boolean;
    OversightOption: string;
    childScreen: string;
    screen: string;
    generatedReportType: string;
    clickProtocol: boolean;
    selectMetrics: Metric[];
    newMetrics: Metric[];
    prvMetrics: Metric[];
    prvPrompt: string | null;
    createReport: boolean;
    versionsHistoryId: null | string;
}

// Initial state with empty protocol list and null session/Eva ID
const initialState: ProtocolState = {
    PrvProtocols: [],
    SessionsId: null,
    EvaId: '', // Initialize this properly
    SessionsReport: null,
    ParentSessionId: null,
    NewProtocols: [],
    newSelectProtocols: [],
    ReportLoading: false,
    OversightOption: '',
    childScreen: '',
    screen: '',
    generatedReportType: '',
    clickProtocol: false,
    selectMetrics: [],
    newMetrics: [],
    prvMetrics: [],
    prvPrompt: '',
    createReport: false,
    versionsHistoryId: null,
};

// Create the slice
const SessionsProtocolsList = createSlice({
    name: 'SessionsProtocols',
    initialState,
    reducers: {
        // Action to set protocols
        setPrvProtocols: (state, action: PayloadAction<Protocol[]>) => {
            state.PrvProtocols = action.payload;
        },
        // Action to set new Select protocols
        setNewProtocols: (state, action: PayloadAction<Protocol[]>) => {
            state.NewProtocols = action.payload;
        },
        setNewSelectedProtocols: (state, action: PayloadAction<Protocol[]>) => {
            state.newSelectProtocols = action.payload;
        },

        setSelectMetrics: (state, action: PayloadAction<Metric[]>) => {
            state.selectMetrics = action.payload;
        },
        setNewMetrics: (state, action: PayloadAction<Metric[]>) => {
            state.newMetrics = action.payload;
        },

        setPrvMetrics: (state, action: PayloadAction<Metric[]>) => {
            state.prvMetrics = action.payload;
        },
        // Action to set session ID
        setSessionsId: (state, action: PayloadAction<string | null>) => {
            state.SessionsId = action.payload;
        },
        setParentSessionId: (state, action: PayloadAction<string | null>) => {
            state.ParentSessionId = action.payload;
        },
        // Action to set Eva ID
        setEvaIds: (state, action: PayloadAction<string>) => {
            state.EvaId = action.payload;
        },
        // Action to set Sessions report
        setSessionsReport: (state, action: PayloadAction<SessionReport | null>) => {
            state.SessionsReport = action.payload;
        },
        setReportLoading: (state, action: PayloadAction<boolean>) => {
            state.ReportLoading = action.payload;
        },

        setOversightOption: (state, action: PayloadAction<string>) => {
            state.OversightOption = action.payload;
        },
        setChildScreen: (state, action: PayloadAction<string>) => {
            state.childScreen = action.payload;
        },
        setScreen: (state, action: PayloadAction<string>) => {
            state.screen = action.payload;
        },
        setGeneratedReportType: (state, action: PayloadAction<string>) => {
            state.generatedReportType = action.payload;
        },
        setClickProtocol: (state, action: PayloadAction<boolean>) => {
            state.clickProtocol = action.payload;
        },
        setPrvPrompt: (state, action: PayloadAction<string | null>) => {
            state.prvPrompt = action.payload;
        },
        setCreateReport: (state, action: PayloadAction<boolean>) => {
            state.createReport = action.payload;
        },
        setVersionsHistoryId: (state, action: PayloadAction<null | string>) => {
            state.versionsHistoryId = action.payload;
        },
    },
});

// Export actions and reducer
export const {
    setPrvProtocols,
    setSessionsId,
    setPrvPrompt,
    setSelectMetrics,
    setPrvMetrics,
    setNewMetrics,
    setClickProtocol,
    setGeneratedReportType,
    setScreen,
    setOversightOption,
    setChildScreen,
    setEvaIds,
    setParentSessionId,
    setSessionsReport,
    setNewProtocols,
    setCreateReport,
    setReportLoading,
    setVersionsHistoryId,
    setNewSelectedProtocols,
} = SessionsProtocolsList.actions;

export default SessionsProtocolsList.reducer;
